<template>
  <section
    id="HomeCompare"
    class="relative pt-32 pb-32 overflow-hidden md:pb-0"
  >
    <div
      class="relative z-10 flex flex-col w-full max-w-screen-xl px-5 mx-auto lg:px-20 sm:px-10"
    >
      <div class="flex flex-col items-center justify-center order-1">
        <div class="relative inline-block mx-auto sm:pt-2">
          <span
            class="title_bg z-0 lg:w-[65%] w-full sm:h-10 h-5 bg-main-orange-color absolute top-0 left-0"
          ></span>
          <h2
            class="relative z-10 inline-block overflow-hidden text-4xl leading-normal text-center text-white title sm:text-6xl sm:leading-normal"
          >
            <span class="block font-bold font-montserrat"
              >WHICH IS BETTER FOR YOU ?</span
            >
          </h2>
        </div>
        <div class="flex items-center mt-4">
          <span
            class="sm:w-32 w-24 h-[1px] bg-white mr-5 sub_title_line"
          ></span>
          <h3
            class="overflow-hidden text-sm font-medium text-white sub_title_text"
          >
            <span class="block">選擇適合您的輪胎</span>
          </h3>
        </div>
      </div>
      <div
        class="flex items-center justify-center order-3 mt-10 md:order-2 md:mt-0 content"
      >
        <main_link_btn
          class="mt-14"
          text_color="#e95402"
          text="COMPARE TYRES"
          link="/compare"
        />
      </div>

      <img
        class="order-3 hidden w-full mx-auto lg:w-2/3 md:block pc_image"
        width="1400"
        height="723"
        alt="瑪吉斯 maxxis s98 比較輪胎"
        src="/img/home_compare/pc_tire.webp"
      />
      <img
        class="order-2 block w-11/12 mx-auto mt-20 sm:w-1/2 md:hidden mb_image"
        width="400"
        height="376"
        alt="瑪吉斯 maxxis s98 比較輪胎"
        src="/img/home_compare/mb_tire.webp"
      />
    </div>

    <img
      class="absolute top-0 left-0 z-0 hidden object-cover w-full h-full md:block"
      width="1400"
      height="788"
      alt="瑪吉斯 maxxis s98 比較輪胎"
      src="/img/home_compare/background.webp"
    /><img
      class="absolute top-0 left-0 z-0 block object-cover w-full h-full md:hidden"
      width="600"
      height="1067"
      alt="瑪吉斯 maxxis s98 比較輪胎"
      src="/img/home_compare/mb_background.webp"
    />
  </section>
</template>

<script>
import main_link_btn from "../main_link_btn.vue";
import HomeCompareGsap from "@/gsap/home/compare";
export default {
  name: "HomeCompare",
  mixins: [HomeCompareGsap],
  components: {
    main_link_btn,
  },
};
</script>
