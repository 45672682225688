/* eslint-disable no-unused-vars */
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});
gsap.registerPlugin(ScrollTrigger)

export default {
    data() {
        return {
            menu_tl: null,
        }
    },
    methods: {
        Open() {
            this.menu_tl != null ? this.menu_tl.kill() : ""
            let menu = document.querySelector("#MainMenu")
            let bg_content = menu.querySelector(".bg_content")
            let content = bg_content.querySelector(".content")
            let menu_list = menu.querySelector(".menu_list")
            let close_btn = menu.querySelector(".close_btn")
            let orange_cover = bg_content.querySelector(".orange_cover")

            this.menu_tl = gsap.timeline()
            this.menu_tl.set(menu, {
                left: 0,
                x: "-100%"
            }, 0)
            this.menu_tl.fromTo(menu, {
                x: "-100%"
            }, {
                x: "0%",
                duration: .4
            }, 0)
            this.menu_tl.fromTo(orange_cover, {
                x: "0%"
            }, {
                x: "100%",
                duration: .6,
                delay: .7
            }, 0)
            this.menu_tl.fromTo(menu_list, {
                opacity: 0,
                y: "10%"
            }, {
                opacity: 1,
                y: "0%"
            }, 1)
            this.menu_tl.fromTo(content, {
                opacity: 0,
                y: "10%"
            }, {
                opacity: 1,
                y: "0%",
                delay: .2
            }, 1)
            this.menu_tl.fromTo(close_btn, {
                opacity: 0
            }, {
                opacity: 1,
                delay: .4
            }, 1)

        },
        Close() {
            this.menu_tl != null ? this.menu_tl.kill() : ""
            let menu = document.querySelector("#MainMenu")
            let bg_content = menu.querySelector(".bg_content")
            let content = bg_content.querySelector(".content")
            let menu_list = menu.querySelector(".menu_list")
            let close_btn = menu.querySelector(".close_btn")
            let orange_cover = bg_content.querySelector(".orange_cover")

            this.menu_tl = gsap.timeline()

            this.menu_tl.fromTo(close_btn, {
                opacity: 1
            }, {
                opacity: 0
            }, 0)
            this.menu_tl.fromTo(content, {
                opacity: 1,
                y: "0%"
            }, {
                opacity: 0,
                y: "10%",
                delay: .4
            }, 0)
            this.menu_tl.fromTo(menu_list, {
                opacity: 1,
                y: "0%"
            }, {
                opacity: 0,
                y: "10%",
                delay: .4
            }, 0)
            this.menu_tl.fromTo(orange_cover, {
                x: "-100%"
            }, {
                x: "0%",
                duration: .4,
                delay: 1
            }, 0)
            this.menu_tl.fromTo(menu, {
                x: "0%"
            }, {
                x: "100%",
                duration: .4,
                delay: .4
            }, 1)


            this.menu_tl.set(menu, {
                left: "-100%",
                x: "0%"
            }, 2)


        },
    },
}