<template>
  <section id="HomeBrands" class="relative">
    <div class="w-full max-w-screen-xl mx-auto sm:px-5">
      <!-- header -->
      <div
        class="flex flex-col px-5 mb-10 lg:flex-row lg:items-center sm:mb-28 sm:px-0"
      >
        <div class="relative pt-2 mr-10">
          <span
            class="title_bg z-0 sm:w-[450px] w-full sm:h-10 h-7 bg-main-orange-color absolute top-0 left-0"
          ></span>
          <h2
            class="relative z-10 pr-5 overflow-hidden text-5xl italic sm:text-8xl title"
          >
            <span class="block pr-5 font-bold font-montserrat">BRANDS</span>
          </h2>
        </div>
        <div class="flex items-center mt-4">
          <span
            class="w-32 h-[1px] bg-main-orange-color mr-5 sub_title_line"
          ></span>
          <h3
            class="overflow-hidden text-sm font-medium text-main-orange-color sub_title_text"
          >
            <span class="block">品牌介紹</span>
          </h3>
        </div>
      </div>
      <!-- header -->

      <BrandCard
        v-for="(item, item_index) in brand_data"
        :key="item.id"
        :brand_data="item"
        :brand_index="item_index"
        :link="item.link"
        @change-carousel="ChangeCardCarousel"
      />
    </div>
  </section>
</template>

<script>
import BrandCard from "./Brand/BrandCard.vue";
import HomeBrandGsap from "@/gsap/home/brands";
export default {
  name: "HomeBrands",
  mixins: [HomeBrandGsap],
  props: {
    value: {
      require: true,
      type: Array,
    },
  },
  components: {
    BrandCard,
  },
  computed: {
    brand_data() {
      return this.value.filter((item) => item.status != "0");
    },
  },
  data() {
    return {
      active_cards: null,
    };
  },
  mounted() {
    let card_array = [];
    this.brand_data.forEach(() => {
      card_array.push(0);
    });
    this.active_cards = card_array;
  },
};
</script>

<style>
#HomeBrands .card_bg {
  -webkit-clip-path: polygon(100% 0, 80% 100%, 0% 100%, 0% 0);
  clip-path: polygon(100% 0, 100% 80%, 0% 100%, 0% 0);
}
#HomeBrands .brand_text {
  -webkit-text-stroke: 1px white;
}
</style>
