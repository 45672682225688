<template>
  <div class="brand_card">
    <!-- brand -->
    <div
      class="relative z-10 flex items-center justify-center overflow-hidden sm:rounded-lg bg_image_box"
    >
      <div
        class="relative z-10 flex flex-col items-center justify-start w-full px-5 md:flex-row py-28 sm:px-10"
      >
        <div
          class="text-center flex flex-col items-center lg:mr-14 md:mr-10 min-w-[300px]"
        >
          <img
            class="mx-auto mb-10 w-60"
            width="240"
            height="120"
            :src="brand_data.logo_image"
            :alt="`瑪吉斯 ${brand_data.title} logo`"
          />
          <main_link_btn
            class="hidden lg:flex"
            text_color="#fff"
            :link="brand_data.link"
            text="BRAND DETAIL"
          />
        </div>
        <div class="lg:ml-14 md:ml-10">
          <div class="flex items-center">
            <span class="w-32 h-[1px] mr-5 bg-white"></span>
            <h4 class="font-bold text-white">{{ brand_data.slogan }}</h4>
          </div>
          <div
            class="mt-5 text-sm font-bold leading-loose text-white"
            v-html="brand_data.brief"
          ></div>
          <!-- <main_link_btn class="flex mt-10 md:hidden" text_color="#fff" /> -->
        </div>
      </div>
      <img
        :alt="brand_data.title"
        width="1200"
        height="1200"
        class="absolute top-0 left-0 w-full h-[200%] object-cover z-0 bg_image"
        :src="brand_data.background_image"
      />
    </div>
    <!-- brand -->
    <div class="w-full overflow-hidden sm:mt-0">
      <!-- brand card -->
      <div class="my-10 brand_carousel whitespace-nowrap sm:-mx-5">
        <div
          v-for="(item, item_index) in GetProduct(brand_data)"
          :key="`card_${item.product_id}`"
          class="inline-block w-full px-5 lg:w-1/3 sm:w-1/2 card"
        >
          <div class="relative z-10 overflow-hidden bg-white rounded-xl">
            <div class="relative flex flex-col h-full">
              <div class="absolute z-0 top-0 left-0 right-0 h-[60%] card_bg">
                <h4
                  class="brand_text absolute w-11/12 text-center whitespace-normal z-10 xl:text-7xl md:text-6xl sm:text-5xl text-transparent text-6xl font-montserrat font-black md:top-[25%] sm:top-[30%] top-[25%] left-1/2 transform"
                >
                  {{ item.brand }}
                </h4>
                <img
                  class="object-cover w-full h-full"
                  width="400"
                  height="800"
                  :alt="item.title"
                  :src="item.background_image"
                />
              </div>
              <h3
                class="relative z-10 px-3 mt-10 mb-24 text-4xl italic font-black text-center text-white card_title font-montserrat sm:text-5xl"
              >
                {{ item.card_title }}
              </h3>
              <router-link
                :to="`product/${item.product_id}`"
                class="relative z-10 flex-1 text-center"
              >
                <img
                  class="w-2/3 mx-auto card_image"
                  width="266"
                  height="266"
                  :src="item.image"
                  :alt="item.title"
                />
              </router-link>
              <div class="mt-10">
                <ol class="flex items-center justify-center">
                  <li
                    class="w-1/3 text-center feature_tag"
                    v-for="tag in item.tags"
                    :key="tag.id"
                  >
                    <img
                      class="object-contain w-8 h-8 mx-auto"
                      width="32"
                      height="32"
                      :src="$store.state.product_tag[tag]"
                      :alt="`瑪吉斯 maxxis s98 輪胎${tag}`"
                    />
                    <p class="text-sm font-bold">{{ tag }}</p>
                  </li>
                </ol>
                <router-link
                  :to="`product/${item.product_id}`"
                  @mouseenter="hover_index = item_index"
                  @mouseleave="hover_index = -1"
                  class="flex items-center justify-center w-full py-5 mt-5 transition-all duration-300 bg-black cursor-pointer hover:bg-main-orange-color sm:py-5"
                >
                  <span
                    class="block text-sm font-bold leading-none text-white uppercase sm:leading-none font-josefin-sans sm:text-base"
                    >TYRE DETAIL</span
                  >
                  <i
                    class="block pb-1 ml-2 text-lg leading-none text-white icon-arrow_right"
                  ></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- brand card -->
    </div>
    <!-- footer -->
    <div
      class="flex items-center justify-between w-full px-5 pb-20 lg:hidden sm:px-0"
    >
      <div class="flex items-center">
        <button
          @click="$emit('change-carousel', brand_index, 0)"
          class="flex items-center justify-center w-10 h-10 mr-2 text-black duration-300 border-2 border-black hover:border-main-orange-color hover:text-main-orange-color transition-color"
        >
          <i class="text-sm icon-arrow_left"></i>
        </button>
        <button
          @click="$emit('change-carousel', brand_index, 1)"
          class="flex items-center justify-center w-10 h-10 ml-2 text-black duration-300 border-2 border-black hover:border-main-orange-color hover:text-main-orange-color transition-color"
        >
          <i class="text-sm icon-arrow_right"></i>
        </button>
      </div>
      <main_link_btn :link="link" text_color="#e95402" />
    </div>
    <!-- footer -->
  </div>
</template>

<script>
import main_link_btn from "@/components/main_link_btn.vue";
export default {
  name: "BrandCard",
  props: {
    brand_data: {
      require: true,
      type: Object,
    },
    brand_index: {
      require: true,
      type: Number,
    },
    link: {
      require: true,
      type: String,
    },
  },
  components: {
    main_link_btn,
  },
  data() {
    return {
      hover_index: -1,
    };
  },
  methods: {
    GetProduct(brand_data) {
      if (this.products == null) {
        return [];
      } else {
        let product_array = [];
        brand_data.products.forEach((id) => {
          if (id != "") {
            let product = this.products.filter(
              (item) => item.product_id == id
            )[0];
            product_array.push(product);
          }
        });
        return product_array;
      }
    },
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
  },
  created() {
    if (this.products == null) {
      this.$store.dispatch("getProductData");
    }
  },
};
</script>
