<template>
  <main id="HomePage" class="home">
    <Carousel :carousel_data="home_page_data.carousel_data" />
    <About :page_data="home_page_data.home_about_content" />
    <Brands v-model="home_page_data.brand_data" />
    <Compare />
    <NewsList :news_data="home_page_data.news_data" />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import About from "@/components/home/About.vue";
import Brands from "@/components/home/Brands.vue";
import Compare from "@/components/home/Compare.vue";
import NewsList from "@/components/home/NewsList.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    About,
    Brands,
    Compare,
    NewsList,
  },
  computed: {
    home_page_data() {
      return this.$store.state.home_page_data;
    },
  },
  mounted() {
    this.$PageReady("瑪吉斯官方網站");
    this.$emit("load-image");
  },
  created() {
    let path = [
      {
        link: "/",
        title: "首頁",
      },
    ];
    this.$emit("set-breadcrumb", path);
    this.$emit("set-meta", "瑪吉斯官方網站", "", "");
    this.$PreLoadImage(
      process.env.VUE_APP_BASEURL +
        "/img/carousel/homepage_1.banner_img_lg_scroll.webp"
    );
  },
};
</script>
