/* eslint-disable no-unused-vars */
import { gsap } from '@/gsap/gsap_loader';
import { ScrollTrigger } from '@/gsap/gsap_loader';

export default {
  data() {
    return {
      brand_tl: null,
      resize_timer: null,
    };
  },
  methods: {
    SetPageEnterGsap() {
      this.brand_tl != null ? this.brand_tl.kill() : '';
      let page = document.querySelector('#HomeBrands');
      let title = page.querySelector('.title span');
      let title_bg = page.querySelector('.title_bg');
      let sub_title_line = page.querySelector('.sub_title_line');
      let sub_title_text = page.querySelector('.sub_title_text span');
      let brand_cards = page.querySelectorAll('.brand_card');

      brand_cards.forEach((item) => {
        this.SetBrandParallaxBg(item);
      });
      this.SetBrandCardsGsap(page);

      this.brand_tl = gsap.timeline({
        scrollTrigger: {
          trigger: page,
          start: 'top 85%',

          toggleActions: 'restart play play reverse',
        },
      });

      this.brand_tl.fromTo(
        title,
        {
          opacity: 1,
          y: '200%',
        },
        {
          opacity: 1,
          y: '0%',
          duration: 0.4,
          ease: 'circ.inOut',
        },
        0
      );
      this.brand_tl.fromTo(
        title_bg,
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 0,
        },
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 1,
          delay: 0.4,
          ease: 'back.out(1.7)',
        },
        0
      );
      this.brand_tl.fromTo(
        sub_title_line,
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 0,
        },
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 1,
          delay: 0.6,
          duration: 0.2,
        },
        0
      );
      this.brand_tl.fromTo(
        sub_title_text,
        {
          opacity: 1,
          x: '-101%',
        },
        {
          opacity: 1,
          x: '0%',
          delay: 1,
          duration: 0.2,
        },
        0
      );
      this.brand_tl.fromTo(
        brand_cards,
        {
          opacity: 0,
          y: '100px',
        },
        {
          opacity: 1,
          y: '0px',
        },
        0
      );
    },
    SetBrandParallaxBg(brand) {
      let image_box = brand.querySelector('.bg_image_box');
      const parallax_top = gsap.timeline({
        scrollTrigger: {
          trigger: brand,
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: true,
        },
      });
      parallax_top.fromTo(
        image_box.querySelector('.bg_image'),
        {
          y: -image_box.offsetHeight,
        },
        {
          y: 0,
          ease: 'linear',
        },
        0
      );
    },
    SetBrandCardsGsap(page) {
      let cards = page.querySelectorAll('.brand_card .card');
      let brand_text = page.querySelectorAll('.brand_card .card .brand_text');
      let card_title = page.querySelectorAll('.brand_card .card .card_title');
      let card_image = page.querySelectorAll('.brand_card .card .card_image');
      if (cards.length <= 0) {
        setTimeout(() => {
          this.SetBrandCardsGsap(page);
        }, 300);
      }
      cards.forEach((card, card_index) => {
        let feature_tag = card.querySelectorAll('.feature_tag');
        let card_tl = gsap.timeline({
          scrollTrigger: {
            trigger: card,
            start: 'top 85%',

            toggleActions: 'restart play play reverse',
            autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
          },
        });
        card_tl.fromTo(
          card,
          {
            // y: "30px",
            opacity: 0,
          },
          {
            // y: "0px",
            opacity: 1,
            duration: 0.4,
          },
          0
        );
        card_tl.fromTo(
          card_image[card_index],
          {
            y: '30px',
            opacity: 0,
          },
          {
            y: '0px',
            opacity: 1,
            duration: 0.3,
            delay: 0.3,
          },
          0
        );
        card_tl.fromTo(
          card_title[card_index],
          {
            y: '30px',
            opacity: 0,
          },
          {
            y: '0px',
            opacity: 1,
            duration: 0.3,
          },
          1
        );
        card_tl.fromTo(
          brand_text[card_index],
          {
            y: '30px',
            x: '-50%',
            opacity: 0,
          },
          {
            y: '0px',
            x: '-50%',
            opacity: 1,
            delay: 0.1,
            duration: 0.3,
          },
          1
        );
        card_tl.fromTo(
          feature_tag,
          {
            scale: 0,
          },
          {
            scale: 1,
            ease: 'back.out(1.7)',
            delay: 0.5,
          },
          1
        );
      });
    },
    ChangeCardCarousel(brand_index, action) {
      let brand_carousel = document.querySelectorAll(
        '#HomeBrands .brand_carousel'
      )[brand_index];
      let active_card = this.active_cards[brand_index];
      let card_count = brand_carousel.querySelectorAll('.card').length;

      active_card = this.ChangeActiveCard(active_card, card_count, action);
      // update brand data
      this.active_cards[brand_index] = active_card;
      // gsap
      gsap.to(brand_carousel, {
        x: active_card * -100 + '%',
      });
    },
    ChangeActiveCard(active_card, card_count, action) {
      let window_size = window.innerWidth;
      if (window_size > 640) {
        //2 cards in one carousel page
        card_count = Math.ceil(card_count / 2);
      }
      if (action == 1) {
        active_card == card_count - 1 ? (active_card = 0) : (active_card += 1);
      }
      // prev card
      else {
        active_card == 0 ? (active_card = card_count - 1) : (active_card -= 1);
      }
      return active_card;
    },
    UpdateBrandData(brand_index, active_card) {
      let tmp_brand_data = JSON.parse(JSON.stringify(this.value));
      tmp_brand_data[brand_index].active_product = active_card;
      this.$emit('input', tmp_brand_data);
    },
    ResetActiveCard() {
      let brand_carousel = document.querySelectorAll(
        '#HomeBrands .brand_carousel'
      );
      this.active_cards.forEach((item, item_index) => {
        this.active_cards[item_index] = 0;
        gsap.to(brand_carousel[item_index], {
          x: '0%',
        });
      });
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded == true && this.brand_data.length > 0) {
        this.$nextTick(() => {
          this.SetPageEnterGsap();
        });
      }
    },
    brand_data() {
      if (this.page_image_loaded == true && this.brand_data.length > 0) {
        this.$nextTick(() => {
          this.SetPageEnterGsap();
        });
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (this.$route.name == 'home') {
        clearTimeout(this.resize_timer);
        this.resize_timer = setTimeout(() => {
          this.ResetActiveCard();
          ScrollTrigger.refresh();
        }, 300);
      }
    });
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
