import Vue from "vue";
import Vuex from "vuex";
import {
  getHomePage,
  getProductData,
  getNewsData,
  getFooterData,
  getAboutPage,
  getVideoData,
  getDealerData,
  getScooterData,
} from "@/api/page";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_menu: false,
    page_image_loaded: false,
    main_loading: 0,
    main_dialog: {
      msg: "",
      status: false,
    },
    image_dialog: {
      status: false,
      image: "",
    },
    product_tag: {
      全熱熔: "/img/product_tag/Icon_Tire_Black.svg",
      半熱熔: "/img/product_tag/Icon_Tire_Black.svg",
      複合胎: "/img/product_tag/Icon_Tire_Black.svg",
      抓地力: "/img/product_tag/Icon_2.svg",
      競技用: "/img/product_tag/Icon_3.svg",
      跑山用: "/img/product_tag/Icon_5.svg",
      跑旅用: "/img/product_tag/Icon_5.svg",
      通勤用: "/img/product_tag/Icon_4.svg",
      晴雨胎: "/img/product_tag/Icon_Tire_Black.svg",
      排水性: "/img/product_tag/Icon_6.svg",
      電動車: "/img/product_tag/Icon_7.svg",
      Approach: "/img/product_tag/節能.webp",
    },
    compare_products: [],
    home_page_data: null,
    products: null,
    product_category: null,
    news_data: null,
    footer_data: null,
    about_page_data: null,
    video_data: null,
    dealer_data: null,
    scooter_data: null,
  },
  getters: {
    // 取得資料讀取狀況
    all_data_loaded(state) {
      if (
        state.home_page_data != null &&
        state.products != null &&
        state.product_category != null &&
        state.news_data != null &&
        state.footer_data != null &&
        state.about_page_data != null &&
        state.video_data != null &&
        state.dealer_data != null &&
        state.scooter_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mutations: {
    SetCompareProducts(state, data) {
      state.compare_products = data;
    },
    SetMainMenu(state, action) {
      state.main_menu = action;
      state.blur = action;
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action;
    },
    SetLoading(state, action) {
      state.main_loading += action;
    },
    SetMainDialog(state, { msg, action }) {
      state.main_dialog.msg = msg;
      state.main_dialog.status = action;
    },
    SetImageModal(state, { status, image }) {
      state.image_dialog.status = status;
      state.image_dialog.image = image;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getAllData(state) {
      state.home_page_data == null ? state.dispatch("getHomePage") : "";
      state.products == null ? state.dispatch("getProductData") : "";
      state.news_data == null ? state.dispatch("getNewsData") : "";
      state.footer_data == null ? state.dispatch("getFooterData") : "";
      state.about_page_data == null ? state.dispatch("getAboutPage") : "";
      state.video_data == null ? state.dispatch("getVideoData") : "";
      state.dealer_data == null ? state.dispatch("getDealerData") : "";
      state.scooter_data == null ? state.dispatch("getScooterData") : "";
    },
    getHomePage(state) {
      getHomePage().then((res) => {
        state.commit("SetStateData", {
          key: "home_page_data",
          val: res.data,
        });
        state.commit("SetStateData", {
          key: "news_data",
          val: res.data.news_data,
        });
      });
    },
    getProductData(state) {
      getProductData().then((res) => {
        state.commit("SetStateData", {
          key: "products",
          val: res.data.products,
        });
        state.commit("SetStateData", {
          key: "product_category",
          val: res.data.category,
        });
      });
    },
    getNewsData(state) {
      getNewsData().then((res) => {
        state.commit("SetStateData", {
          key: "news_data",
          val: res.data,
        });
      });
    },
    getFooterData(state) {
      getFooterData().then((res) => {
        state.commit("SetStateData", {
          key: "footer_data",
          val: res.data,
        });
      });
    },
    getAboutPage(state) {
      getAboutPage().then((res) => {
        state.commit("SetStateData", {
          key: "about_page_data",
          val: res.data,
        });
      });
    },
    getVideoData(state) {
      getVideoData().then((res) => {
        state.commit("SetStateData", {
          key: "video_data",
          val: res.data,
        });
      });
    },
    getDealerData(state) {
      getDealerData().then((res) => {
        state.commit("SetStateData", {
          key: "dealer_data",
          val: res.data,
        });
      });
    },
    getScooterData(state) {
      getScooterData().then((res) => {
        state.commit("SetStateData", {
          key: "scooter_data",
          val: res.data,
        });
      });
    },
  },
  modules: {},
});
