/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});
gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      contact_tl: null,
      active_page: 0,
    };
  },
  methods: {
    SetPageEnterGsap() {
      this.contact_tl != null ? this.contact_tl.kill() : '';
      let page = document.querySelector('#FooterContact');
      let title_image = page.querySelector('.title_image');
      let title = page.querySelector('.title span');
      let title_bg = page.querySelector('.title_bg');
      let sub_title_line = page.querySelector('.sub_title_line');
      let sub_title_text = page.querySelector('.sub_title_text span');
      let cards = page.querySelectorAll('.card');
      let content = page.querySelectorAll('.card .content');
      let bg_image = page.querySelectorAll('.card .bg_image');
      let bg_cover = page.querySelectorAll('.card .bg_cover');

      gsap.set(
        bg_image,
        {
          opacity: 0,
        },
        0
      );
      gsap.set(
        bg_cover,
        {
          scaleX: 0,
        },
        0
      );

      this.contact_tl = gsap.timeline({
        scrollTrigger: {
          trigger: page,
          start: 'top 85%',
          toggleActions: 'restart play play reverse',
        },
      });
      this.contact_tl.fromTo(
        title_image,
        {
          x: '-60px',
          opacity: 0,
        },
        {
          x: '0px',
          opacity: 1,
          duration: 0.4,
          ease: 'circ.inOut',
        },
        0
      );
      this.contact_tl.fromTo(
        title,
        {
          opacity: 1,
          y: '200%',
        },
        {
          opacity: 1,
          y: '0%',
          duration: 0.4,
          ease: 'circ.inOut',
        },
        0
      );
      this.contact_tl.fromTo(
        title_bg,
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 0,
        },
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 1,
          delay: 0.4,
          ease: 'back.out(1.7)',
        },
        0
      );
      this.contact_tl.fromTo(
        sub_title_line,
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 0,
        },
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 1,
          delay: 1,
          duration: 0.6,
        },
        0
      );
      this.contact_tl.fromTo(
        sub_title_text,
        {
          opacity: 1,
          x: '-101%',
        },
        {
          opacity: 1,
          x: '0%',
          delay: 1.6,
          duration: 0.4,
        },
        0
      );

      // card animation
      this.contact_tl.fromTo(
        bg_cover[0],
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
          delay: 0.4,
          duration: 0.3,
        },
        0
      );
      this.contact_tl.fromTo(
        bg_cover[1],
        {
          scaleX: 0,
          transformOrigin: 'left',
        },
        {
          scaleX: 1,
          transformOrigin: 'left',
          delay: 0.6,
          duration: 0.3,
        },
        0
      );

      this.contact_tl.set(
        bg_image,
        {
          opacity: 1,
          delay: 0.9,
        },
        0
      );
      this.contact_tl.to(
        bg_cover[0],
        {
          scaleX: 0,
          transformOrigin: 'right',
          duration: 0.3,
        },
        1
      );
      this.contact_tl.to(
        bg_cover[1],
        {
          scaleX: 0,
          transformOrigin: 'right',
          duration: 0.3,
        },
        1
      );
      this.contact_tl.fromTo(
        content,
        {
          opacity: 0,
          y: '100px',
        },
        {
          opacity: 1,
          y: '0px',
          duration: 0.3,
        },
        1
      );
    },
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded == true) {
        this.$nextTick(() => {
          this.SetPageEnterGsap();
        });
      }
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
