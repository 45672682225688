<template>
  <button
    class="relative p-5"
    @click="ScrollToTop"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <svg
      class="absolute top-0 left-1/2 transform -translate-x-1/2"
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      viewBox="0 0 103.23 52.33"
    >
      <g id="圖層_2" data-name="圖層 2">
        <g id="圖層_1-2" data-name="圖層 1">
          <path
            d="M.26,47.43a4.4,4.4,0,0,1,.88-2.49,2.18,2.18,0,0,1,2-.79,2.59,2.59,0,0,1,1.54.62,2.69,2.69,0,0,1,.84,1.35A5,5,0,0,1,5.68,48l-1.11.23A6.58,6.58,0,0,1,5.11,46a3.59,3.59,0,0,1,1.14-1.47,2.24,2.24,0,0,1,1.6-.44,3,3,0,0,1,1.39.43,2.64,2.64,0,0,1,.87.89,3.57,3.57,0,0,1,.41,1.19,5.44,5.44,0,0,1,0,1.34l-.29,3.42L0,50.44Zm4.22.13a1,1,0,0,0-.29-.91,1.27,1.27,0,0,0-.79-.37.92.92,0,0,0-.83.3,1.54,1.54,0,0,0-.35.92l-.09,1.09,2.24.2Zm4.19.29a2.74,2.74,0,0,0,0-.82,1.17,1.17,0,0,0-.33-.6,1,1,0,0,0-.64-.27.87.87,0,0,0-.69.2A1.32,1.32,0,0,0,6.6,47a3.15,3.15,0,0,0-.17.82L6.33,49l2.23.19Z"
          />
          <path
            d="M12,39.55l-8-8.47L4,31l11.67-.09-.92,2.17-8.09-.22-.35-1.75,6.46,6.62ZM9.79,35.17,11,32.32h1.85l-1.74,4.09Z"
          />
          <path
            d="M22.91,20.55a3.68,3.68,0,0,1-.13.72,6.17,6.17,0,0,1-.41,1.11,5.33,5.33,0,0,1-.83,1.21A6,6,0,0,1,19.77,25a4.78,4.78,0,0,1-3.82.16,5.53,5.53,0,0,1-1.71-1,6.27,6.27,0,0,1-1.4-1.68,5.1,5.1,0,0,1-.6-1.88,4.7,4.7,0,0,1,.22-1.93,5.18,5.18,0,0,1,1-1.8,5.5,5.5,0,0,1,1.41-1.2A6.37,6.37,0,0,1,16.23,15l.92,1.86a5.63,5.63,0,0,0-1,.35A2.88,2.88,0,0,0,15,18a2.67,2.67,0,0,0-.56,1,3,3,0,0,0-.11,1.16,3.53,3.53,0,0,0,1.18,2.21,4.31,4.31,0,0,0,1.16.73,3.14,3.14,0,0,0,1.22.23,2.83,2.83,0,0,0,1.14-.29,3.35,3.35,0,0,0,1-.81A3.59,3.59,0,0,0,20.82,21,3.43,3.43,0,0,0,21,20Z"
          />
          <path
            d="M26.17,11.08l.22.47.06-.49.95-5,2.26-1.35-1.29,6.15,6.57,2.7L32.73,14.9l-5-2.16-.17.7,2,3.34-1.77,1L22.53,9,24.3,7.93Z"
          />
          <path
            d="M47.15.15,54.09,0l0,2L51.62,2l.18,8.34-2,.05-.18-8.34-2.4,0Z"
          />
          <path
            d="M61.12,5.94a5,5,0,0,1,1-1.8,5.35,5.35,0,0,1,3.52-1.87,5,5,0,0,1,2.08.21,5.19,5.19,0,0,1,1.81,1,5.34,5.34,0,0,1,1.25,1.62,5.14,5.14,0,0,1,.59,1.94,4.92,4.92,0,0,1-.2,2.05,5.06,5.06,0,0,1-1,1.83,5.14,5.14,0,0,1-1.6,1.25,5.38,5.38,0,0,1-1.94.57,5.22,5.22,0,0,1-2.06-.23,5.12,5.12,0,0,1-1.82-1A5.32,5.32,0,0,1,61.45,10,5.42,5.42,0,0,1,60.89,8,5.07,5.07,0,0,1,61.12,5.94Zm2,.63a3.26,3.26,0,0,0,.18,2.51,3.27,3.27,0,0,0,.76,1,3.16,3.16,0,0,0,1.14.63,2.94,2.94,0,0,0,1.24.12,3,3,0,0,0,1.16-.37,3.6,3.6,0,0,0,.94-.8,3.39,3.39,0,0,0,.61-1.16,3.32,3.32,0,0,0,.15-1.32,3.26,3.26,0,0,0-1-2.18,3.12,3.12,0,0,0-1.12-.61,3,3,0,0,0-1.26-.14,3.09,3.09,0,0,0-1.18.38,3.15,3.15,0,0,0-.95.8A3.28,3.28,0,0,0,63.12,6.57Z"
          />
          <path
            d="M87.91,14.71l4.62,5.18L91.07,21.2,89.4,19.33l-6.22,5.56-1.36-1.52,6.23-5.55L86.45,16Z"
          />
          <path
            d="M92.59,29.1a5,5,0,0,1,2-.42,5.4,5.4,0,0,1,2,.37,5.35,5.35,0,0,1,1.72,1.09,5.1,5.1,0,0,1,1.19,1.72,5,5,0,0,1,.41,2,5.5,5.5,0,0,1-.41,2,5.27,5.27,0,0,1-1.1,1.72,5,5,0,0,1-1.69,1.17,4.92,4.92,0,0,1-2,.42,5.24,5.24,0,0,1-2-.4A5.39,5.39,0,0,1,91,37.69a5.27,5.27,0,0,1-1.59-3.75,5.32,5.32,0,0,1,.37-2,5.39,5.39,0,0,1,1.11-1.69A5,5,0,0,1,92.59,29.1ZM93.41,31a3.45,3.45,0,0,0-1.09.72,3.59,3.59,0,0,0-.7,1A3.23,3.23,0,0,0,91.38,34a3,3,0,0,0,.26,1.27,3,3,0,0,0,1.75,1.66,3.59,3.59,0,0,0,1.22.2,3.66,3.66,0,0,0,1.28-.29,3.38,3.38,0,0,0,1.1-.74,3.25,3.25,0,0,0,.7-1,2.94,2.94,0,0,0,0-2.44A3,3,0,0,0,97,31.59a3.15,3.15,0,0,0-1-.65,3.18,3.18,0,0,0-1.23-.2A3.35,3.35,0,0,0,93.41,31Z"
          />
          <path
            d="M103.21,48.11a4.7,4.7,0,0,1-.27,2.18,3,3,0,0,1-1.1,1.43,3.38,3.38,0,0,1-1.79.6,4.29,4.29,0,0,1-1.28-.1,3.35,3.35,0,0,1-1.2-.51,3,3,0,0,1-.92-1A4.13,4.13,0,0,1,96.22,49l-.11-1.45-3.26.25-.15-2L103,45Zm-5,.73a1.79,1.79,0,0,0,.22.76,1.32,1.32,0,0,0,.44.44,1.4,1.4,0,0,0,.53.21,1.67,1.67,0,0,0,.47,0,2.14,2.14,0,0,0,.4-.09,1.6,1.6,0,0,0,.52-.23,1.32,1.32,0,0,0,.41-.51,1.69,1.69,0,0,0,.11-.85l-.11-1.45-3.1.23Z"
          />
        </g>
      </g>
    </svg>
    <svg
      class="transform transition-all duration-300"
      :class="hover ? 'rotate-180' : ''"
      xmlns="http://www.w3.org/2000/svg"
      height="60"
      width="60"
      viewBox="0 0 75 75"
    >
      <g id="圖層_2" data-name="圖層 2">
        <g id="圖層_1-2" data-name="圖層 1">
          <path
            d="M37.55,75A37.5,37.5,0,1,1,75,37.44,37.56,37.56,0,0,1,37.55,75ZM37.48,8.82a28.68,28.68,0,0,0,0,57.36,29,29,0,0,0,28.7-28.73A28.87,28.87,0,0,0,37.48,8.82Zm-28,46.3C2.07,39,3.24,24.34,16.75,13.09,31.27,1,46.53,2.9,61.08,14.2,50.42,1.52,29.19.36,15.87,11.57,3,22.39-.38,43.59,9.43,55.12Z"
          />
          <path
            d="M11.76,37.63A25.77,25.77,0,1,1,37.63,63.22,25.8,25.8,0,0,1,11.76,37.63ZM13.14,37c0,14,10.59,24.89,24.17,24.88a24.36,24.36,0,1,0-.14-48.72C24.1,13.19,13.1,24.09,13.14,37Z"
          />
          <path
            d="M30.73,17.26c1.69-1,2.66-.78,3.31.91q1.83,4.71,3.82,9.34a4.46,4.46,0,0,0,6.07,2.35C47,28.45,50,27,53.07,25.57c1.74-.8,1.75-.78,3,1.38-2.64,2.3-5.29,4.65-8,7-3.5,3-3.6,4.85-.36,8.29,2,2.1,3.9,4.27,6,6.27,1.51,1.45,1.15,2.39-.51,3.38-3.36-2-6.71-4.09-10.1-6.09a4.21,4.21,0,0,0-6.27,1.72c-1.65,2.93-3.18,5.93-4.91,8.82-.39.64-1.41.9-2.13,1.33-.24-.81-.8-1.7-.67-2.45.61-3.2,1.45-6.37,2.16-9.56s-.71-5.22-3.83-5.82S21.17,38.69,18.08,38c-.75-.17-1.36-.94-2-1.44.73-.56,1.4-1.51,2.19-1.61,3.14-.4,6.32-.56,9.49-.76s4.88-2.27,4.5-5.32c-.47-3.82-1-7.62-1.49-11.59Zm6.85,24.4a4.09,4.09,0,0,0,4.07-4.28,4.15,4.15,0,0,0-8.3.14,4.09,4.09,0,0,0,4.22,4.14Z"
          />
          <path
            d="M27.88,18.25c.5,4,1.05,7.35,1.29,10.76a2.72,2.72,0,0,1-1.64,2.1c-3.46.48-7,.67-10.85,1a22,22,0,0,1,11.2-13.86Z"
          />
          <path
            d="M51.19,54.06A21.61,21.61,0,0,1,34,58.62c1.88-3.44,3.51-6.65,5.4-9.7.31-.49,2-.64,2.7-.27C45.05,50.22,47.86,52,51.19,54.06Z"
          />
          <path
            d="M57.43,29.67c2.29,6.14,1.91,11.69-.83,17.7C53.87,44.52,51.33,42,49,39.29a2.52,2.52,0,0,1,.24-2.45C51.78,34.42,54.5,32.19,57.43,29.67Z"
          />
          <path
            d="M16.27,40.69c3.89.74,7.3,1.27,10.63,2.15a3,3,0,0,1,1.39,2.51c-.56,3.3-1.47,6.53-2.36,10.21A21.44,21.44,0,0,1,16.27,40.7Z"
          />
          <path
            d="M36.22,16.11a21.52,21.52,0,0,1,16.59,6.33c-3.54,1.64-6.76,3.22-10.08,4.55a2.56,2.56,0,0,1-2.29-1c-1.48-3.1-2.72-6.31-4.22-9.88Z"
          />
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: "ScrollTopBtn",
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>