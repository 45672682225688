import { render, staticRenderFns } from "./ScrollTopBtn.vue?vue&type=template&id=3979b155&"
import script from "./ScrollTopBtn.vue?vue&type=script&lang=js&"
export * from "./ScrollTopBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports