<template>
  <div
    class="bullet_box absolute lg:top-[68%] md:top-[75%] md:bottom-auto top-auto bottom-60 lg:left-40 left-10 transform z-10 items-center sm:flex hidden"
  >
    <span
      class="bullet_arrow relative block h-[0px] w-[0px] border-[10px] border-solid border-l-main-orange-color border-t-transparent border-r-transparent border-b-transparent md:mr-10 mr-10"
    ></span>
    <ol class="h-full relative w-[300px]">
      <li
        v-for="(item, item_index) in carousel_data"
        :key="item.id"
        @click="$emit('change-carousel', item_index)"
        class="absolute top-1/2 z-[11] transform bullet cursor-pointer"
        :style="`transform:translateY(-50%);left:${
          bullet_pos_percent * item_index
        }%`"
      >
        <span class="block w-2 h-2 bg-white rounded-full"></span>
      </li>

      <span
        class="absolute block w-6 h-6 transform -translate-y-1/2 border border-white rounded-full opacity-0 active_circle md:w-6 md:h-6 top-1/2 -translate-x-1/3"
      ></span>
      <span
        class="line relative w-full block h-[1px] bg-white bg-opacity-50"
      ></span>
    </ol>
  </div>
</template>

<script>
export default {
  name: "CarouselPageBullet",
  props: {
    carousel_data: {
      require: true,
      type: Array,
    },
    active_carousel: {
      require: true,
      type: Number,
    },
  },
  methods: {},
  computed: {
    bullet_pos_percent() {
      return (1 / (this.carousel_data.length - 1)) * 100;
    },
  },
};
</script>