import { gsap, ScrollTrigger } from "@/gsap/gsap_loader";

export class image_loading_animation {
  constructor(el) {
    this.el = el;
    this.road = el.querySelector(".road");
    this.loading_text = el.querySelector(".loading_text");

    this.timeline = null;

    window.addEventListener("resize", () => {
      ScrollTrigger.refresh();
    });
  }

  reset() {
    this.timeline != null ? this.timeline.kill() : "";
    gsap.set(this.el, {
      x: "0%",
      // opacity: 0,
    });

    gsap.set(this.loading_text, {
      opacity: 1,
    });

    gsap.set(this.road, {
      strokeDashoffset: "0",
    });
  }

  open() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();

    document.body.style.overflow = "hidden";

    this.timeline.set(
      this.el,
      {
        x: "-100%",
      },
      "first"
    );

    // this.timeline.fromTo(
    //   this.el,
    //   {
    //     opacity: 0,
    //   },
    //   {
    //     opacity: 1,
    //   },
    //   "same"
    // );

    this.timeline.fromTo(
      this.loading_text,
      {
        opacity: 1,
      },
      {
        opacity: 0.3,
        duration: 0.7,
        ease: "linear",
        repeat: -1,
      },
      "same"
    );

    this.timeline.fromTo(
      this.road,
      {
        strokeDashoffset: "0",
      },
      {
        strokeDashoffset: "79",
        ease: "none",
        repeat: -1,
        duration: 0.4,
      },
      "same"
    );
  }

  close() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline({
      onComplete: () => {
        document.body.style.overflow = "auto";
        window.scrollTo(0, 0);
        ScrollTrigger.refresh();
      },
    });
    this.timeline.to(
      this.loading_text,
      {
        opacity: 0,
      },
      "first"
    );
    this.timeline.to(
      this.road,
      {
        strokeDashoffset: "79",
      },
      "first"
    );

    this.timeline.to(
      this.el,
      {
        x: "0%",
      },
      "first"
    );
  }
}
