<template>
  <footer
    id="MainFooter"
    class="relative w-full py-32 border-t-4 border-main-orange-color md:py-52"
  >
    <div class="absolute hidden top-20 right-20 md:block">
      <ScrollTopBtn />
    </div>
    <div
      v-if="footer_data != null"
      class="flex flex-wrap justify-between w-full max-w-screen-xl px-10 mx-auto xl:px-0"
    >
      <div class="w-full md:w-1/2 sm:w-1/3">
        <img
          alt="瑪吉斯 maxxis s98 logo"
          width="208"
          height="24"
          class="w-52 mb-14"
          src="/img/MAXXIS S98_B.png"
        />
        <h4 class="mb-5 text-2xl font-bold">
          {{ footer_data.company_name.content }}
        </h4>
        <p class="mb-2 text-sm font-semibold font-montserrat">
          TEL {{ footer_data.company_phone.content }}
        </p>
        <p class="text-sm font-semibold font-montserrat">
          FAX {{ footer_data.company_fax.content }}
        </p>
      </div>
      <div
        class="flex justify-between w-full mt-20 md:w-1/2 sm:w-2/3 md:pl-0 sm:pl-10 sm:mt-0 sm:justify-end"
      >
        <ol class="-mt-3 md:mr-28 sm:mr-14">
          <li class="py-3 text-sm font-semibold font-montserrat sm:text-base">
            <router-link to="/about"> ABOUT US </router-link>
          </li>
          <li class="py-3 text-sm font-semibold font-montserrat sm:text-base">
            <router-link to="/product_list/all"> BROWSE TIRES </router-link>
          </li>
          <li class="py-3 text-sm font-semibold font-montserrat sm:text-base">
            <router-link to="/compare"> COMPARE TIRES </router-link>
          </li>
          <li class="py-3 text-sm font-semibold font-montserrat sm:text-base">
            <router-link to="/news_list/all">NEWS</router-link>
          </li>
          <li class="py-3 text-sm font-semibold font-montserrat sm:text-base">
            <router-link to="/video_list">VIDEO</router-link>
          </li>
        </ol>
        <ol class="-mt-3">
          <li class="py-3 text-sm font-semibold sm:text-base font-montserrat">
            <router-link to="/dealers">FIND YOUR DEALER</router-link>
          </li>
          <li class="py-3 text-sm font-semibold sm:text-base font-montserrat">
            <router-link to="/contact_us">CONTACT US</router-link>
          </li>
          <li class="py-3 text-sm font-semibold sm:text-base font-montserrat">
            -
          </li>
          <li class="py-3 text-sm font-semibold sm:text-base font-montserrat">
            FOLLOW US
          </li>
          <li
            class="flex items-center py-3 text-sm font-semibold sm:text-base font-montserrat"
          >
            <a
              class="mr-4"
              :href="footer_data.company_facebook.content"
              target="_blank"
            >
              <i class="text-2xl icon-facebook"></i>
            </a>
            <a :href="footer_data.company_instagram.content" target="_blank">
              <i class="text-2xl icon-instagram"></i>
            </a>
          </li>
        </ol>
      </div>
    </div>
  </footer>
</template>

<script>
import ScrollTopBtn from "@/components/ScrollTopBtn.vue";
export default {
  name: "MainFooter",
  components: {
    ScrollTopBtn,
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
  },
  created() {
    if (this.footer_data == null) {
      this.$store.dispatch("getFooterData");
    }
  },
};
</script>
