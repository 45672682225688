<template>
  <section id="HomeNewsList" class="relative py-32 bg-main-orange-color">
    <div class="relative z-10 w-full max-w-screen-xl px-5 mx-auto sm:px-10">
      <div class="flex flex-col sm:flex-row">
        <div class="relative pt-2 pr-4">
          <span
            class="absolute top-0 left-0 z-0 w-1/2 h-10 bg-black title_bg sm:w-full"
          ></span>
          <h2
            class="relative z-10 overflow-hidden text-6xl italic text-white title sm:text-7xl"
          >
            <span class="block pr-5 font-bold font-montserrat">NEWS</span>
          </h2>
          <img
            width="164"
            height="46"
            class="absolute top-0 right-0 w-1/3 transform translate-x-4 title_image sm:w-auto sm:right-full sm:left-auto sm:translate-x-0"
            alt="瑪吉斯 maxxis s98 background_line"
            src="/img/title_image_1.svg"
          />
        </div>
        <div class="flex items-center mt-6 sm:ml-5 sm:mt-0">
          <span class="w-32 h-[1px] bg-white mr-5 sub_title_line"></span>
          <h3
            class="overflow-hidden text-sm font-medium text-white sub_title_text"
          >
            <span class="block">最新消息</span>
          </h3>
        </div>
      </div>

      <div class="content">
        <div class="w-full pb-10 mt-20 overflow-hidden">
          <ol class="-mx-5 whitespace-nowrap main_carousel">
            <li
              v-for="item in news_data"
              :key="`news_${item.news_id}`"
              class="relative inline-block w-full px-5 lg:w-1/3 sm:w-1/2"
            >
              <router-link :to="`/news/${item.news_id}`">
                <div
                  class="w-full overflow-hidden aspect-w-1 aspect-h-1 rounded-xl"
                >
                  <img
                    width="400"
                    height="400"
                    :alt="`瑪吉斯 maxxis s98 ${item.title}`"
                    :src="item.cover_image"
                  />
                </div>
                <div
                  class="absolute w-4/5 p-5 bg-white rounded-lg shadow-lg -bottom-10 left-5"
                >
                  <div class="font-bold">
                    [<span class="relative px-1">
                      <i
                        class="absolute top-0 left-0 right-0 z-0 h-3 bg-green-400"
                      ></i>
                      <b class="relative z-10 text-sm font-medium">{{
                        item.category
                      }}</b> </span
                    >]
                  </div>
                  <h4 class="max-w-full text-lg font-bold truncate">
                    {{ item.title }}
                  </h4>
                  <p
                    class="mt-2 text-xs font-bold tracking-wider font-montserrat"
                  >
                    {{ item.create_at }}
                  </p>
                </div>
              </router-link>
            </li>
          </ol>
        </div>
        <!-- footer -->
        <div class="flex items-center justify-between w-full mt-20">
          <div class="flex items-center">
            <button
              @click="ChangeCarouselPage(0)"
              class="flex items-center justify-center w-10 h-10 mr-2 text-white duration-300 border-2 border-white hover:border-black hover:text-black transition-color"
            >
              <i class="text-sm icon-arrow_left"></i>
            </button>
            <button
              @click="ChangeCarouselPage(1)"
              class="flex items-center justify-center w-10 h-10 ml-2 text-white duration-300 border-2 border-white hover:border-black hover:text-black transition-color"
            >
              <i class="text-sm icon-arrow_right"></i>
            </button>
          </div>
          <main_link_btn
            text_color="#fff"
            icon_color="#fff"
            border_color="#fff"
            link="/news_list/all"
            text="ALL NEWS LIST"
          />
        </div>
        <!-- footer -->
      </div>
    </div>

    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg_image mix-blend-multiply opacity-30"
      style="background-image: url('/img/bg_el.webp')"
    ></div>
  </section>
</template>

<script>
import main_link_btn from "@/components/main_link_btn";
import HomeNewsGsap from "@/gsap/home/news";
export default {
  name: "HomeNewsList",
  mixins: [HomeNewsGsap],
  components: {
    main_link_btn,
  },
  props: {
    news_data: {
      require: true,
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
#HomeNewsList .bg_image {
  background-size: 100px;
  background-repeat: repeat;
}
</style>
