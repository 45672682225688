/* eslint-disable no-unused-vars */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.config({
  autoSleep: 60,
  force3D: true,
  nullTargetWarn: false,
  trialWarn: false,
  units: {
    left: '%',
    top: '%',
    rotation: 'rad',
  },
});
gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      news_tl: null,
      active_page: 0,
    };
  },
  methods: {
    SetPageEnterGsap() {
      this.news_tl != null ? this.news_tl.kill() : '';
      let page = document.querySelector('#HomeNewsList');
      let title_image = page.querySelector('.title_image');
      let title = page.querySelector('.title span');
      let title_bg = page.querySelector('.title_bg');
      let sub_title_line = page.querySelector('.sub_title_line');
      let sub_title_text = page.querySelector('.sub_title_text span');
      let content = page.querySelector('.content');

      this.news_tl = gsap.timeline({
        scrollTrigger: {
          trigger: page,
          start: 'top 85%',
          toggleActions: 'restart play play reverse',
        },
      });

      this.news_tl.fromTo(
        title_image,
        {
          x: '-60px',
          opacity: 0,
        },
        {
          x: '0px',
          opacity: 1,
          duration: 0.4,
          ease: 'circ.inOut',
        },
        0
      );

      this.news_tl.fromTo(
        title,
        {
          opacity: 1,
          y: '200%',
        },
        {
          opacity: 1,
          y: '0%',
          duration: 0.4,
          ease: 'circ.inOut',
        },
        0
      );
      this.news_tl.fromTo(
        title_bg,
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 0,
        },
        {
          opacity: 1,
          transformOrigin: 'left',
          scaleX: 1,
          delay: 0.4,
          ease: 'back.out(1.7)',
        },
        0
      );
      this.news_tl.fromTo(
        sub_title_line,
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 0,
        },
        {
          transformOrigin: 'left',
          opacity: 1,
          scaleX: 1,
          delay: 1,
          duration: 0.6,
        },
        0
      );
      this.news_tl.fromTo(
        sub_title_text,
        {
          opacity: 1,
          x: '-101%',
        },
        {
          opacity: 1,
          x: '0%',
          delay: 1.6,
          duration: 0.4,
        },
        0
      );
      this.news_tl.fromTo(
        content,
        {
          opacity: 0,
          y: '20px',
        },
        {
          opacity: 1,
          y: '0px',
          delay: 1,
          duration: 0.4,
        },
        0
      );
    },
    ChangeCarouselPage(action) {
      let news_count = this.news_data.length;
      let data_per_page = 0;
      let window_size = window.innerWidth;
      window_size > 1024
        ? (data_per_page = 3)
        : window_size > 640
        ? (data_per_page = 2)
        : (data_per_page = 1);
      let page_count = Math.ceil(news_count / data_per_page);
      let main_carousel = document.querySelector(
        '#HomeNewsList .main_carousel'
      );

      if (action == 1) {
        this.active_page == page_count - 1
          ? (this.active_page = 0)
          : (this.active_page += 1);
      } else {
        this.active_page == 0
          ? (this.active_page = page_count - 1)
          : (this.active_page -= 1);
      }
      gsap.to(main_carousel, {
        x: this.active_page * -100 + '%',
      });
    },
    ResetCarousel() {
      let main_carousel = document.querySelector(
        '#HomeNewsList .main_carousel'
      );
      this.active_page = 0;

      gsap.to(main_carousel, {
        x: '0%',
      });
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (this.$route.name == 'home') {
        clearTimeout(this.resize_timer);
        this.resize_timer = setTimeout(() => {
          this.ResetCarousel();
        }, 300);
      }
    });
  },
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded == true && this.news_data.length > 0) {
        this.$nextTick(() => {
          this.SetPageEnterGsap();
        });
      }
    },
    news_data() {
      if (this.page_image_loaded == true && this.news_data.length > 0) {
        this.$nextTick(() => {
          this.SetPageEnterGsap();
        });
      }
    },
  },
  computed: {
    page_image_loaded() {
      return this.$store.state.page_image_loaded;
    },
  },
};
