<template>
  <div id="app" class="overflow-x-hidden bg-main-gray-color">
    <MainDialog />
    <MainLoading />
    <template v-if="all_data_loaded">
      <MainHeader @open-menu="OpenMenu" />
      <MainMenu ref="MainMenu" />
      <BreadCrumb :path="breadcrumb_path" />
      <router-view
        @load-image="LoadImage"
        @set-meta="SetMeta"
        @set-breadcrumb="SetBreadCrumb"
      />
      <ContactFooter />
      <MainFooter />
    </template>
  </div>
</template>

<style src="@/assets/css/output.css"></style>

<script>
import ContactFooter from "@/components/FooterContact.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainLoading from "./components/MainLoading.vue";
import MainDialog from "@/components/MainDialog.vue";
import BreadCrumb from "./components/BreadCrumb.vue";
import { GetMetaData } from "@/common/meta";
import { getLocalStorage } from "@/common/cookie";
import { ImageLoader } from "@/gsap/image_loader";
export default {
  name: "App",
  components: {
    ContactFooter,
    MainFooter,
    MainHeader,
    MainMenu,
    MainDialog,
    MainLoading,
    BreadCrumb,
  },
  data() {
    return {
      image_loader: null,
      meta_data: null,
      breadcrumb_path: [
        {
          title: "首頁",
          link: "/",
        },
      ],
    };
  },
  methods: {
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
    SetMeta(title = "", content = "", image = "") {
      this.meta_data = GetMetaData(title, content, image);
    },
    SetBreadCrumb(path) {
      console.log(path);
      this.breadcrumb_path = path;
    },
    InitCompareData() {
      let compare_products = getLocalStorage("compare_products");
      if (compare_products) {
        this.$store.commit("SetCompareProducts", JSON.parse(compare_products));
      }
    },
    LoadImage() {
      this.image_loader.LoadImage();
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
  },
  mounted() {
    this.image_loader = new ImageLoader();
  },
  created() {
    this.all_data_loaded ? "" : this.$store.dispatch("getAllData");
    this.InitCompareData();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
