<template>
  <section id="HomeAbout" class="relative">
    <div
      class="grid w-full max-w-screen-xl grid-cols-3 grid-rows-3 gap-0 pt-40 mx-auto sm:px-10 lg:py-40 lg:grid-cols-2 auto-rows-fr"
    >
      <div
        class="lg:row-span-3 row-span-2 lg:col-span-1 col-span-3 lg:min-h-[500px] h-[400px] relative overflow-hidden lg:order-1 order-2"
      >
        <img
          alt="瑪吉斯 maxxis s98 賽道"
          width="475"
          height="500"
          class="motor_image absolute bottom-0 right-10 z-10 lg:w-[550px] w-[500px]"
          src="/img/home_about/homepage_2.about_img_lg_2.webp"
        />
        <img
          alt="瑪吉斯 maxxis s98 賽車"
          width="475"
          height="298"
          class="bg_image absolute z-0 top-0 left-0 lg:w-[500px] w-3/4 lg:h-[500px] h-[400px] object-cover object-right"
          src="/img/home_about/homepage_2.about_img_lg_1.webp"
        />
      </div>
      <div
        class="order-1 col-span-3 row-span-1 px-5 lg:col-span-1 lg:order-2 lg:pl-0 sm:pl-10 sm:pr-0"
      >
        <div class="relative inline-block pt-2 pr-2">
          <span
            class="absolute top-0 left-0 z-0 w-full title_bg sm:h-10 h-7 bg-main-orange-color"
          ></span>
          <h2
            class="relative z-10 inline-block px-2 overflow-hidden text-5xl italic sm:text-7xl title"
          >
            <span class="block pr-3 font-bold font-montserrat">ABOUT US</span>
          </h2>
        </div>
        <div class="flex items-center mt-4">
          <span
            class="w-32 h-[1px] bg-main-orange-color mr-5 sub_title_line"
          ></span>
          <h3
            class="overflow-hidden text-sm font-medium text-main-orange-color sub_title_text"
          >
            <span class="block">關於我們</span>
          </h3>
        </div>
      </div>
      <div
        class="order-3 col-span-3 row-span-2 px-5 lg:col-span-1 sm:col-span-2 lg:px-0 sm:px-10"
      >
        <div
          class="flex-1 mt-10 text-sm leading-relaxed tracking-wider content"
          v-html="page_data.content"
        ></div>
        <div class="w-full text-left lg:text-right more_btn_box">
          <main_link_btn class="mt-20" link="/about" text="about maxxis" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import main_link_btn from "../main_link_btn.vue";
import HomeAboutGsap from "@/gsap/home/about";
export default {
  name: "HomeAbout",
  mixins: [HomeAboutGsap],
  components: {
    main_link_btn,
  },
  props: {
    page_data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
#HomeAbout .bg_image {
  -webkit-clip-path: polygon(100% 0, 80% 100%, 0% 100%, 0% 0);
  clip-path: polygon(100% 0, 80% 100%, 0% 100%, 0% 0);
}
</style>
