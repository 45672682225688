<template>
  <div
    v-if="status"
    class="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center"
  >
    <div
      class="w-[500px] max-w-[95%] z-10 p-5 bg-repeat"
      style="background-image: url('/img/modal_bg_sm.jpg')"
    >
      <div class="flex justify-end">
        <button
          class="flex items-center justify-center w-8 h-8 text-white transition-all duration-300 border border-white hover:bg-white hover:text-main-orange-color"
          @click="Close"
        >
          <i class="text-xs icon-close"></i>
        </button>
      </div>
      <div class="mt-10 text-center">
        <p class="mb-10 text-lg font-medium text-white">{{ content }}</p>
        <button
          class="px-10 py-1 text-lg font-bold text-white transition-all duration-300 border border-white font-montserrat hover:bg-white hover:text-main-orange-color"
          @click="Close"
        >
          CLOSE
        </button>
      </div>
    </div>
    <div
      class="absolute top-0 left-0 z-0 w-full h-full bg-black opacity-70"
    ></div>
  </div>
</template>

<script>
export default {
  name: "MainDialog",
  methods: {
    Close() {
      this.$store.commit("SetMainDialog", { msg: "", action: false });
    },
  },
  computed: {
    content() {
      return this.$store.state.main_dialog.msg;
    },
    status() {
      return this.$store.state.main_dialog.status;
    },
  },
};
</script>