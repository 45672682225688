<template>
  <header id="MainHeader" class="fixed top-0 left-0 right-0 z-30">
    <div
      class="relative z-30 flex items-center justify-between w-full px-5 py-5 sm:py-6 lg:px-20 sm:px-10"
    >
      <router-link to="/" class="relative">
        <img
          alt="瑪吉斯 maxxis s98 logo"
          width="128"
          height="15"
          :class="menu_bg_show ? 'opacity-100' : 'opacity-0'"
          class="absolute top-0 bottom-0 left-0 right-0 w-32 transition-opacity duration-300"
          src="/img/MAXXIS S98_B.png"
        />
        <img
          alt="瑪吉斯 maxxis s98 logo"
          width="128"
          height="15"
          class="block w-32"
          src="/img/MAXXIS S98_W.png"
        />
      </router-link>
      <button @click="$emit('open-menu')" class="flex items-center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0138 20C4.51224 20.0169 0.0199443 15.5369 -5.56832e-05 10.0154C-0.0200556 4.51691 4.48147 0 9.98146 0C15.4968 0 19.9707 4.45845 19.9999 9.98459C20.0276 15.4615 15.5184 19.983 10.0138 20ZM9.99377 2.3523C5.78455 2.36307 2.36148 5.78922 2.35071 10.0046C2.33994 14.1861 5.79685 17.64 10.0015 17.6492C14.1522 17.6584 17.6584 14.1507 17.6568 9.98767C17.6568 5.81076 14.1753 2.34153 9.99377 2.3523ZM2.51532 14.7C0.550712 10.3969 0.863019 6.49075 4.46609 3.49076C8.33839 0.267692 12.4076 0.772306 16.2876 3.78614C13.4445 0.406153 7.78454 0.0969227 4.2307 3.08615C0.799943 5.97075 -0.101594 11.623 2.51532 14.7Z"
            fill="#EA5404"
          />
          <path
            d="M3.13526 10.0338C3.11219 6.25842 6.15987 3.17997 9.96755 3.13381C13.6752 3.08766 16.8275 6.16765 16.8768 9.88457C16.9275 13.6784 13.8168 16.8492 10.0337 16.8599C6.23218 16.8707 3.15834 13.8307 3.13526 10.0353V10.0338ZM3.50449 9.85841C3.5168 13.6015 6.3291 16.4953 9.95063 16.4922C13.6045 16.4892 16.5091 13.5799 16.5014 9.93226C16.4937 6.35534 13.5537 3.48612 9.91217 3.5015C6.42602 3.51689 3.49372 6.42457 3.50449 9.85841V9.85841Z"
            fill="#EA5404"
          />
          <path
            d="M8.19536 4.60314C8.64459 4.32468 8.90305 4.39391 9.07689 4.84468C9.40151 5.6816 9.74458 6.51083 10.0954 7.33544C10.3707 7.98159 11.08 8.2539 11.7138 7.96159C12.5292 7.58621 13.3369 7.19544 14.1523 6.81852C14.6153 6.60467 14.6184 6.61083 14.9461 7.18621C14.243 7.80005 13.5369 8.42774 12.8184 9.04313C11.8861 9.84313 11.86 10.337 12.7215 11.2539C13.2492 11.8139 13.7615 12.3924 14.3153 12.9247C14.7169 13.3108 14.6215 13.5631 14.18 13.8262C13.283 13.2847 12.3892 12.737 11.4861 12.2031C10.8338 11.817 10.1846 11.9954 9.81228 12.66C9.37382 13.4431 8.96459 14.2431 8.50305 15.0123C8.39997 15.1831 8.1292 15.2523 7.93536 15.3693C7.87074 15.1508 7.72151 14.9154 7.75843 14.7154C7.91997 13.86 8.14305 13.017 8.33228 12.1662C8.51689 11.3416 8.14305 10.7739 7.31228 10.6139C6.48151 10.4539 5.64459 10.317 4.82152 10.1293C4.62152 10.0831 4.45844 9.87697 4.27844 9.74313C4.47075 9.5939 4.65075 9.34159 4.85998 9.31543C5.69844 9.20928 6.54613 9.16467 7.39074 9.11236C8.2092 9.06005 8.69228 8.50621 8.59228 7.69236C8.46766 6.67544 8.33074 5.6616 8.19382 4.60314H8.19536ZM10.02 11.1093C10.6477 11.1 11.1292 10.5939 11.1061 9.96774C11.0846 9.37543 10.6107 8.90774 10.0184 8.89851C9.39689 8.88774 8.89228 9.38467 8.89382 10.0062C8.89536 10.637 9.38612 11.1185 10.0184 11.1093H10.02Z"
            fill="#EA5404"
          />
          <path
            d="M7.43378 4.86768C7.56916 5.92152 7.71532 6.82613 7.77993 7.7369C7.79224 7.91998 7.51839 8.27074 7.34147 8.29536C6.4184 8.42459 5.48455 8.47536 4.44763 8.56151C4.95225 6.87536 5.88455 5.71537 7.43532 4.86768H7.43378Z"
            fill="#EA5404"
          />
          <path
            d="M13.6507 14.4169C12.2461 15.4953 10.8061 15.8707 9.0676 15.6323C9.5676 14.7138 10.003 13.8584 10.5076 13.0461C10.5891 12.9153 11.0414 12.8738 11.2261 12.9723C12.0122 13.3907 12.7614 13.8784 13.6507 14.4169V14.4169Z"
            fill="#EA5404"
          />
          <path
            d="M15.3137 7.9108C15.926 9.54925 15.8245 11.0293 15.0937 12.6308C14.366 11.8708 13.6891 11.1969 13.066 10.4785C12.9629 10.3585 13.0045 9.9431 13.1306 9.8231C13.8076 9.17849 14.5322 8.58464 15.3137 7.9108Z"
            fill="#EA5404"
          />
          <path
            d="M4.33835 10.8507C5.37527 11.0492 6.28604 11.1907 7.17219 11.423C7.35065 11.4692 7.57988 11.8877 7.54449 12.0938C7.39373 12.9723 7.15219 13.8353 6.91527 14.8169C5.47065 13.8523 4.66758 12.6015 4.33835 10.8523V10.8507Z"
            fill="#EA5404"
          />
          <path
            d="M9.65988 4.29539C11.423 4.22924 12.7999 4.77539 14.0829 5.98462C13.1383 6.42 12.2799 6.84154 11.3937 7.19846C11.243 7.25846 10.8599 7.08923 10.7845 6.93077C10.3891 6.10462 10.0583 5.2477 9.65988 4.29693V4.29539Z"
            fill="#EA5404"
          />
        </svg>
        <span
          class="ml-2 text-xs font-bold font-montserrat sm:text-base text-main-orange-color"
          >MENU</span
        >
      </button>
    </div>

    <div
      class="bg_cover z-[29] absolute top-0 left-0 right-0 bottom-0 transition-all duration-300"
      :class="menu_bg_show ? 'bg-white shadow-lg' : ''"
    ></div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      menu_bg_show: false,
      menu_bg_timer: null,
      hide_pages: ["search_tire", "home", "about"],
    };
  },
  watch: {
    $route() {
      this.menu_bg_show =
        this.hide_pages.indexOf(this.$route.name) != -1 ? false : true;
    },
  },
  mounted() {
    this.menu_bg_show =
      this.hide_pages.indexOf(this.$route.name) != -1 ? false : true;
    window.addEventListener("scroll", () => {
      if (this.hide_pages.indexOf(this.$route.name) != -1) {
        clearTimeout(this.menu_bg_timer);
        this.menu_bg_timer = setTimeout(() => {
          window.scrollY > 200
            ? (this.menu_bg_show = true)
            : (this.menu_bg_show = false);
        }, 50);
      }
    });
  },
};
</script>
