<template>
  <router-link
    :to="link"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="relative inline-flex items-center pl-5 cursor-pointer select-none"
  >
    <span
      class="absolute left-0 block w-10 h-10 transform -translate-y-1/2 top-1/2"
    >
      <i
        class="absolute top-0 left-0 right-0 h-[2px]"
        :style="`background-color:${border_color}`"
      ></i>
      <i
        class="absolute bottom-0 left-0 right-0 h-[2px]"
        :style="`background-color:${border_color}`"
      ></i>
      <i
        class="absolute top-0 left-0 bottom-0 w-[2px]"
        :style="`background-color:${border_color}`"
      ></i>
      <i
        class="absolute top-0 right-0 w-[2px] h-[10px]"
        :style="`background-color:${border_color}`"
      ></i>
      <i
        class="absolute right-0 bottom-0 w-[2px] h-[10px]"
        :style="`background-color:${border_color}`"
      ></i>
    </span>
    <p
      class="text-sm font-bold uppercase transition-transform duration-200 font-montserrat"
      :class="hover ? 'translate-x-4' : ''"
      :style="`color:${text_color}`"
    >
      {{ text }}
    </p>
    <i
      class="ml-2 text-lg transition-transform duration-200 icon-arrow_right"
      :class="hover ? 'translate-x-4' : ''"
      :style="`color:${icon_color}`"
    ></i>
  </router-link>
</template>

<script>
export default {
  name: "MainLinBtn",
  props: {
    link: {
      default: "/",
    },
    text: {
      default: "learn more",
    },
    text_color: {
      require: true,
      default: "black",
    },
    icon_color: {
      default: "#e95402",
    },
    border_color: {
      default: "#e95402",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
