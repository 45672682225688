import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact_us",
    name: "contact_us",
    component: () =>
      import(/* webpackChunkName: "contact_us" */ "../views/GetInTouch.vue"),
  },
  {
    path: "/become_dealer",
    name: "become_dealer",
    component: () =>
      import(
        /* webpackChunkName: "become_dealer" */ "../views/BecomeDealer.vue"
      ),
  },
  {
    path: "/news_list/:category",
    name: "news_list",
    component: () =>
      import(/* webpackChunkName: "news_list" */ "../views/NewsList.vue"),
  },
  {
    path: "/news/:id",
    name: "news_page",
    component: () =>
      import(/* webpackChunkName: "news_page" */ "../views/NewsPage.vue"),
  },
  {
    path: "/video_list",
    name: "video_list",
    component: () =>
      import(/* webpackChunkName: "video_list" */ "../views/VideoList.vue"),
  },
  {
    path: "/video/:id",
    name: "video_page",
    component: () =>
      import(/* webpackChunkName: "video_page" */ "../views/VideoPage.vue"),
  },
  // {
  //   path: '/dealers',
  //   name: 'dealer',
  //   component: () =>
  //     import(/* webpackChunkName: "dealer" */ '../views/Dealer.vue'),
  // },
  {
    path: "/dealers",
    name: "dealers",
    component: () =>
      import(/* webpackChunkName: "new_dealers" */ "../views/NewDealer.vue"),
  },
  {
    path: "/search_tire/:type",
    name: "search_tire",
    component: () =>
      import(/* webpackChunkName: "search_tire" */ "../views/SearchTire.vue"),
  },
  {
    path: "/product_list/:id",
    name: "product_list",
    component: () =>
      import(/* webpackChunkName: "product_list" */ "../views/ProductList.vue"),
  },
  {
    path: "/product/:id",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductDetail.vue"),
  },
  {
    path: "/compare",
    name: "compare",
    component: () =>
      import(/* webpackChunkName: "compare" */ "../views/ComparePage.vue"),
  },
  {
    path: "/error_page",
    name: "error_page",
    component: () =>
      import(/* webpackChunkName: "error_page" */ "../views/ErrorPage.vue"),
  },
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/index.php",
    redirect: "/",
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  store.commit("SetImageLoaded", false);
  Vue.nextTick(() => {
    next();
  });
});

export default router;
