<template>
  <div
    class="absolute bottom-0 z-10 flex flex-col items-stretch justify-between w-full max-w-screen-lg overflow-hidden transform -translate-x-1/2 search_bar lg:absolute md:relative left-1/2 md:bg-main-orange-color sm:bg-transparent bg-main-orange-color md:flex-row"
  >
    <span
      class="absolute z-0 hidden rounded-full w-52 h-52 right-6 -top-36 circle-clip md:hidden sm:block"
    ></span>

    <div
      class="relative z-10 flex items-center justify-center w-full px-5 py-4 lg:pl-8 md:pl-4 md:pr-0 sm:py-6 md:w-2/5 sm:justify-start"
    >
      <i class="mr-2 text-4xl text-white lg:mr-5 icon-search"></i>
      <div class="">
        <h4
          class="text-base font-bold tracking-widest text-white font-montserrat lg:text-xl md:text-base sm:text-xl"
        >
          SEARCH FOR YOUR TIRES
        </h4>
        <div class="flex items-center">
          <span class="flex-1 h-[1px] bg-white mr-4"></span>
          <h4
            class="text-xs font-medium tracking-wider text-white sm:text-base"
          >
            尋找適合您的輪胎
          </h4>
        </div>
      </div>
    </div>

    <div class="relative z-10 flex items-stretch w-full md:w-3/5 md:mx-0">
      <router-link
        to="search_tire/vehicle"
        class="relative flex items-center justify-center w-1/2 h-full py-6 overflow-hidden search_box md:py-0"
      >
        <div class="relative z-10 flex items-center">
          <img
            class="hidden h-10 mr-2 sm:block"
            alt="瑪吉斯 maxxis s98 車種搜尋"
            width="40"
            height="40"
            src="/img/carousel/Icon_Vehicle.svg"
          />
          <div class="text-white">
            <h4 class="font-bold leading-none tracking-wider font-montserrat">
              BY VEHICLE
            </h4>
            <p
              class="flex items-center mt-1 text-sm leading-none tracking-wider sm:mt-0 sm:text-base"
            >
              依車型搜尋
              <i class="ml-1 text-lg icon-arrow_right"></i>
            </p>
          </div>
        </div>
        <img
          class="absolute top-0 left-0 object-cover object-center w-full h-full"
          alt="瑪吉斯 maxxis s98 車種搜尋"
          width="308"
          height="100"
          src="/img/carousel/homepage_1.search bar_img_lg_1.webp"
        />
      </router-link>
      <router-link
        to="search_tire/size"
        class="relative flex items-center justify-center w-1/2 h-full overflow-hidden tire_search_box"
      >
        <div class="relative z-10 flex items-center">
          <img
            class="hidden h-10 mr-2 sm:block"
            alt="瑪吉斯 maxxis s98 尺寸搜尋"
            width="40"
            height="40"
            src="/img/carousel/Icon_Tire.svg"
          />
          <div class="text-white">
            <h4 class="font-bold leading-none tracking-wider font-montserrat">
              BY TIRE SIZE
            </h4>
            <p
              class="flex items-center mt-1 text-sm leading-none tracking-wider sm:mt-0 sm:text-base"
            >
              依尺寸搜尋
              <i class="ml-1 text-lg icon-arrow_right"></i>
            </p>
          </div>
        </div>
        <img
          class="absolute top-0 left-0 object-cover object-center w-full h-full"
          alt="瑪吉斯 maxxis s98 尺寸搜尋"
          width="308"
          height="100"
          src="/img/carousel/homepage_1.search bar_img_lg_2.webp"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselSearchBar",
};
</script>

<style>
#HomeCarousel .search_box {
  -webkit-clip-path: polygon(100% 0, 90% 100%, 0% 100%, 10% 0);
  clip-path: polygon(100% 0, 90% 100%, 0% 100%, 10% 0);
  transform: translateX(10%);
}
#HomeCarousel .tire_search_box {
  -webkit-clip-path: polygon(100% 0, 90% 100%, 0% 100%, 10% 0);
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 0);
}

@media (max-width: 768px) {
  #HomeCarousel .search_box {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 0);
    transform: translateX(0%);
    width: 52.5%;
  }
  #HomeCarousel .tire_search_box {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 5% 0);
    position: absolute;
    top: 0;
    right: 0;
  }

  #HomeCarousel .circle-clip {
    box-shadow: 0px 0px 0px 9999px #e95402;
  }
}
</style>
