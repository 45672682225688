<template>
  <div
    ref="MainContent"
    id="MainLoading"
    class="fixed top-0 bottom-0 z-50 flex items-center justify-center w-full left-full bg-main-orange-color"
  >
    <div>
      <svg
        width="260"
        class="max-w-[80%] mx-auto"
        viewBox="0 0 260 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 128L260 128"
          stroke="white"
          stroke-width="5"
          stroke-dasharray="30 10"
          class="road"
        />
        <rect class="road_border" y="97" width="260" height="1" fill="white" />
        <rect
          class="road_border_opacity"
          x="70"
          width="120"
          height="120"
          rx="60"
          fill="#EA5404"
          fill-opacity="0.9"
        />
        <g class="wheel">
          <path
            d="M130.069 110C102.562 110.084 80.1003 87.6844 80.0003 60.0768C79.9003 32.5846 102.408 10 129.908 10C157.484 10 179.853 32.2923 179.999 59.923C180.138 87.3075 157.592 109.915 130.069 110ZM129.969 21.7615C108.923 21.8154 91.8079 38.9461 91.7541 60.023C91.7002 80.9306 108.985 98.1998 130.008 98.2459C150.761 98.2921 168.292 80.7537 168.284 59.9383C168.284 39.0538 150.877 21.7077 129.969 21.7615ZM92.5771 83.4998C82.7542 61.9845 84.3157 42.4538 102.331 27.4538C121.692 11.3385 142.038 13.8615 161.438 28.9307C147.223 12.0308 118.923 10.4846 101.154 25.4307C84.0003 39.8538 79.4926 68.1152 92.5771 83.4998Z"
            fill="white"
          />
          <path
            d="M95.6764 60.1692C95.561 41.2923 110.799 25.9001 129.838 25.6693C148.376 25.4385 164.137 40.8385 164.383 59.4231C164.637 78.3923 149.084 94.2461 130.168 94.2999C111.161 94.3538 95.7918 79.1538 95.6764 60.1769V60.1692ZM97.5226 59.2923C97.5841 78.0076 111.646 92.4768 129.753 92.4614C148.022 92.4461 162.545 77.8999 162.507 59.6615C162.468 41.777 147.768 27.4308 129.561 27.5078C112.13 27.5847 97.4687 42.1231 97.5226 59.2923Z"
            fill="white"
          />
          <path
            d="M120.977 33.0157C123.223 31.6234 124.515 31.9695 125.385 34.2234C127.008 38.408 128.723 42.5541 130.477 46.6772C131.854 49.908 135.4 51.2695 138.569 49.808C142.646 47.931 146.684 45.9772 150.761 44.0926C153.077 43.0234 153.092 43.0541 154.731 45.931C151.215 49.0003 147.684 52.1387 144.092 55.2156C139.431 59.2156 139.3 61.6848 143.608 66.2695C146.246 69.0694 148.808 71.9617 151.577 74.6233C153.584 76.554 153.107 77.8156 150.9 79.131C146.415 76.4233 141.946 73.6848 137.431 71.0156C134.169 69.0848 130.923 69.9771 129.062 73.3002C126.869 77.2156 124.823 81.2156 122.515 85.0617C122 85.9156 120.646 86.2617 119.677 86.8463C119.354 85.754 118.608 84.5771 118.792 83.5771C119.6 79.3002 120.715 75.0848 121.662 70.831C122.585 66.7079 120.715 63.8695 116.562 63.0695C112.408 62.2695 108.223 61.5848 104.108 60.6464C103.108 60.4156 102.293 59.3849 101.393 58.7156C102.354 57.9695 103.254 56.7079 104.3 56.5772C108.493 56.0464 112.731 55.8233 116.954 55.5618C121.046 55.3002 123.462 52.531 122.962 48.4618C122.339 43.3772 121.654 38.308 120.969 33.0157H120.977ZM130.1 65.5464C133.238 65.5002 135.646 62.9695 135.531 59.8387C135.423 56.8772 133.054 54.5387 130.092 54.4926C126.985 54.4387 124.462 56.9233 124.469 60.031C124.477 63.1848 126.931 65.5925 130.092 65.5464H130.1Z"
            fill="white"
          />
          <path
            d="M117.169 34.3384C117.846 39.6076 118.577 44.1307 118.9 48.6845C118.961 49.5999 117.592 51.3537 116.707 51.4768C112.092 52.123 107.423 52.3768 102.238 52.8076C104.761 44.3768 109.423 38.5768 117.177 34.3384H117.169Z"
            fill="white"
          />
          <path
            d="M148.253 82.0848C141.23 87.4771 134.03 89.3541 125.338 88.1617C127.838 83.5694 130.015 79.2925 132.538 75.231C132.946 74.5772 135.207 74.3695 136.13 74.8618C140.061 76.9541 143.807 79.3925 148.253 82.0848Z"
            fill="white"
          />
          <path
            d="M156.568 49.5532C159.63 57.7455 159.122 65.1455 155.468 73.1532C151.83 69.3532 148.445 65.984 145.33 62.3917C144.815 61.7917 145.022 59.7147 145.653 59.1147C149.038 55.8917 152.661 52.9224 156.568 49.5532Z"
            fill="white"
          />
          <path
            d="M101.692 64.2532C106.877 65.2455 111.431 65.9532 115.861 67.1147C116.754 67.3455 117.9 69.4378 117.723 70.4685C116.969 74.8608 115.761 79.1762 114.577 84.0839C107.354 79.2608 103.339 73.007 101.692 64.2609V64.2532Z"
            fill="white"
          />
          <path
            d="M128.301 31.4774C137.116 31.1467 144.001 33.8774 150.416 39.9236C145.693 42.1005 141.401 44.2082 136.97 45.9928C136.216 46.2928 134.301 45.4466 133.924 44.6543C131.947 40.5236 130.293 36.239 128.301 31.4851V31.4774Z"
            fill="white"
          />
        </g>
      </svg>

      <p
        class="px-5 italic font-bold text-center text-white loading_text font-montserrat"
      >
        LOADING...
      </p>
    </div>
  </div>
</template>

<script>
import { image_loading_animation } from "@/gsap/image_loading_animation";
export default {
  name: "MainLoading",
  computed: {
    loading() {
      return this.$store.state.main_loading;
    },
  },
  data() {
    return {
      image_loading_animation: null,
      timer: null,
    };
  },
  watch: {
    loading() {
      if (this.loading != 0) {
        this.image_loading_animation.open();
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.image_loading_animation.close();
        }, 500);
      }
    },
  },
  mounted() {
    this.image_loading_animation = new image_loading_animation(
      this.$refs.MainContent
    );
    this.image_loading_animation.reset();
  },
};
</script>