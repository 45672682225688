/* eslint-disable no-unused-vars */
import gsap from "gsap"

gsap.config({
    autoSleep: 60,
    force3D: true,
    nullTargetWarn: false,
    trialWarn: false,
    units: {
        left: "%",
        top: "%",
        rotation: "rad"
    }
});

export default {
    data() {
        return {
            active_carousel: 0,
            carousel_timer: null,
            carousel_tl: null,
            page_enter_tl: null,
            pager_line_tl: null,
            pager_circle_tl: null,
            scroll_circle_tl: null,
            scroll_circle_img_tl: null
        }
    },
    methods: {
        SetCircleGsap(page) {

            this.scroll_circle_tl != null ? this.scroll_circle_tl.kill() : ''
            this.scroll_circle_img_tl != null ? this.scroll_circle_img_tl.kill() : ''
            this.scroll_circle_tl = gsap.timeline({
                repeat: -1
            })
            this.scroll_circle_img_tl = gsap.timeline({
                repeat: -1
            })
            let scroll_circle = page.querySelectorAll(".scroll_circle img")[1]
            let scroll_circle_img = page.querySelectorAll(".scroll_circle img")[0]

            this.scroll_circle_tl.fromTo(scroll_circle, {
                transformOrigin: "center",
                rotation: "0deg"
            }, {
                transformOrigin: "center",
                rotation: "360deg",
                duration: 8,
                ease: "none"
            }, 0)
            this.scroll_circle_img_tl.fromTo(scroll_circle_img, {
                transformOrigin: "center",
                scale: .8
            }, {
                transformOrigin: "center",
                scale: 1,
                ease: "back.out(1.7)"
            }, 0)
            this.scroll_circle_img_tl.fromTo(scroll_circle_img, {
                transformOrigin: "center",
                scale: 1,
            }, {
                transformOrigin: "center",
                scale: .8,
                ease: "back.out(1.7)"
            }, 1)

        },
        SetPagerGsap(page) {
            let active_number_pc_line = page.querySelector(".active_number .pc_line")
            let active_number_mb_line = page.querySelector(".active_number .mb_line")
            this.pager_line_tl != null ? this.pager_line_tl.kill() : ''

            this.pager_line_tl = gsap.timeline({
                repeat: -1
            })

            // infinte line animation
            this.pager_line_tl.fromTo(active_number_pc_line, {
                scaleY: 0,
                transformOrigin: "top"
            }, {
                scaleY: 1,
                transformOrigin: "top"
            }, 0)
            this.pager_line_tl.fromTo(active_number_pc_line, {
                scaleY: 1,
                transformOrigin: "bottom"
            }, {
                scaleY: 0,
                transformOrigin: "bottom"
            }, 1)
            this.pager_line_tl.fromTo(active_number_mb_line, {
                scaleX: 0,
                transformOrigin: "left"
            }, {
                scaleX: 1,
                transformOrigin: "left"
            }, 0)
            this.pager_line_tl.fromTo(active_number_mb_line, {
                scaleX: 1,
                transformOrigin: "right"
            }, {
                scaleX: 0,
                transformOrigin: "right"
            }, 1)
        },
        SetActiveCircleGsap(page) {
            let bullet_circle = page.querySelector(".bullet_box .active_circle")
            this.pager_circle_tl != null ? this.pager_circle_tl.kill() : ""
            this.pager_circle_tl = gsap.timeline({
                repeat: -1
            })
            // infinte active circle animation
            this.pager_circle_tl.fromTo(bullet_circle, {
                scale: 1,
                transformOrigin: "center"
            }, {
                scale: 1.2,
                transformOrigin: "center"
            }, 0)
            this.pager_circle_tl.fromTo(bullet_circle, {
                scale: 1.2,
                transformOrigin: "center"
            }, {
                scale: 1,
                transformOrigin: "center"
            }, 1)
        },
        SetPageEnterGsap() {
            this.page_enter_tl != null ? this.page_enter_tl.kill() : ''
            this.page_enter_tl = gsap.timeline({
                onComplete: () => {
                    vm.SetCarouselTimer()
                }
            })

            let vm = this
            let page = document.querySelector("#HomeCarousel")
            let search_bar = page.querySelector(".search_bar")
            let bullet = page.querySelectorAll(".bullet_box .bullet")
            let bullet_arrow = page.querySelector(".bullet_box .bullet_arrow")
            let bullet_line = page.querySelector(".bullet_box .line")
            let bullet_circle = page.querySelector(".bullet_box .active_circle")
            let carousel_item = page.querySelectorAll(".carousel_item")[0]
            let content_box = carousel_item.querySelector(".content_box")
            let content_box_line = content_box.querySelector(".content_box span")
            let content_box_text = content_box.querySelector(".content_box p span")
            let text_box = carousel_item.querySelector(".text_box")
            let bg_box = text_box.querySelector(".bg_box")
            let title = text_box.querySelector(".title")
            let sub_title = text_box.querySelector(".sub_title")
            this.SetPagerGsap(page)
            this.SetCircleGsap(page)


            //0 search bar up
            this.page_enter_tl.fromTo(search_bar, {
                yPercent: 100,
            }, {
                yPercent: 0,
                duration: .4,
                delay: .4
            }, 0)
            // 1 bullet_arrow in
            this.page_enter_tl.fromTo(bullet_arrow, {
                x: "-20px",
                opacity: 0,
            }, {
                x: "0px",
                opacity: 1,
                duration: .6,
            }, 1)
            //1 timeline grow
            this.page_enter_tl.fromTo(bullet_line, {
                scaleX: 0,
                transformOrigin: "left"
            }, {
                scaleX: 1,
                transformOrigin: "left",
                duration: 1.5,
            }, 1)
            //1 timeline circle grow
            this.page_enter_tl.fromTo(bullet_circle, {
                scale: 0,
                opacity: 1,
            }, {
                scale: 1,
                opacity: 1,
                duration: .4,
                delay: 1.5,
                onComplete: () => {
                    setTimeout(() => {
                        vm.SetActiveCircleGsap(page)
                    }, 300)
                }
            }, 1)

            //1 bullet show
            bullet.forEach((item, item_index) => {

                this.page_enter_tl.fromTo(item, {
                    scale: 0,
                    opacity: 0,
                }, {
                    scale: 1,
                    opacity: 1,
                    duration: .3,
                    delay: 1.5 + (item_index * 0.1)
                }, 1)
            })
            //2 content line grow
            this.page_enter_tl.fromTo(content_box_line, {
                scale: 0,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scale: 1,
                transformOrigin: "left",
                opacity: 1,
                duration: .6,
                delay: .4
            }, 1)
            //2 title up
            this.page_enter_tl.fromTo(title, {
                y: "200%",
                opacity: 1,
            }, {
                y: "0%",
                opacity: 1,
                duration: .8,
                delay: .8,
                ease: "slow(0.7, 0.7, false)"
            }, 1)
            //2 sub_title up
            this.page_enter_tl.fromTo(sub_title, {
                y: "200%",
                opacity: 1,
            }, {
                y: "0%",
                opacity: 1,
                duration: .8,
                delay: .9,
                ease: "slow(0.7, 0.7, false)"
            }, 1)
            //2 content text up
            this.page_enter_tl.fromTo(content_box_text, {
                x: "-100%",
                opacity: 1,
            }, {
                x: "0%",
                opacity: 1,
                duration: .8,
                delay: 1.3,
                ease: "circ.inOut"
            }, 1)
            //2 bg grow
            this.page_enter_tl.fromTo(bg_box, {
                scaleX: 0,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scaleX: 1,
                transformOrigin: "left",
                opacity: 1,
                duration: 1,
                delay: 1.2,
                ease: "back.out(1.7)"
            }, 1)


        },

        SetCarouselTimer(next_index = -1) {
            let vm = this
            this.carousel_timer != null ? clearTimeout(this.carousel_timer) : ""
            this.carousel_timer = setTimeout(() => {
                if (this.$route.name == "home") {

                    if (next_index != -1) {
                        vm.ChangeCarousel(next_index)
                    } else {
                        vm.active_carousel == vm.carousel_data.length - 1 ? vm.ChangeCarousel(0) : vm.ChangeCarousel(vm.active_carousel + 1)
                    }
                }
            }, 5000)
        },

        ChangeCarousel(card_index) {

            this.carousel_timer != null ? clearTimeout(this.carousel_timer) : ""
            let vm = this
            let page = document.querySelector("#HomeCarousel")
            let carousel_items = page.querySelectorAll(".carousel_item")
            let out_card = carousel_items[this.active_carousel]
            let in_card = carousel_items[card_index]
            let main_carousel_box = page.querySelector(".main_carousel_box")
            let bullet_box = page.querySelector(".bullet_box ol")
            let bullet_circle = page.querySelector(".bullet_box .active_circle")
            let active_circle_pos = bullet_box.clientWidth / (this.carousel_data.length - 1) * card_index

            this.active_carousel = card_index
            this.carousel_tl != null ? this.carousel_tl.kill() : ""
            this.carousel_tl = gsap.timeline({
                onComplete: () => {
                    vm.SetCarouselTimer()
                }
            })
            // animate active circle
            this.carousel_tl.to(bullet_circle, {
                left: active_circle_pos + "px"
            }, 0)
            //animate out now card
            this.carousel_tl = this.AnimateOut(this.carousel_tl, out_card)
            //animate change card
            this.carousel_tl.to(main_carousel_box, {
                x: (card_index * -100) + "%",
                duration: .6,
                ease: "circ.inOut"
            }, 1)
            //animate in next card
            this.carousel_tl = this.AnimateIn(this.carousel_tl, in_card)
        },
        AnimateOut(tl, card) {
            let content_box = card.querySelector(".content_box")
            let content_box_line = content_box.querySelector(".content_box span")
            let content_box_text = content_box.querySelector(".content_box p span")
            let text_box = card.querySelector(".text_box")
            let bg_box = text_box.querySelector(".bg_box")
            let title = text_box.querySelector(".title")
            let sub_title = text_box.querySelector(".sub_title")

            //2 content text up
            tl.fromTo(content_box_text, {
                x: "0%",
                opacity: 1,
            }, {
                x: "-100%",
                opacity: 1,
                duration: .8,
                ease: "circ.inOut"
            }, 0)
            //2 bg grow
            tl.fromTo(bg_box, {
                scaleX: 1,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scaleX: 0,
                transformOrigin: "left",
                opacity: 1,
                duration: 1,
                delay: .1,
                ease: "back.out(1.7)"
            }, 0)
            //2 sub_title up
            tl.fromTo(sub_title, {
                y: "0%",
                opacity: 1,
            }, {
                y: "200%",
                opacity: 1,
                duration: .8,
                delay: .3,
                ease: "slow(0.7, 0.7, false)"
            }, 0)
            //2 title up
            tl.fromTo(title, {
                y: "0%",
                opacity: 1,
            }, {
                y: "200%",
                opacity: 1,
                duration: .8,
                delay: .4,
                ease: "slow(0.7, 0.7, false)"
            }, 0)
            //2 content line grow
            tl.fromTo(content_box_line, {
                scale: 1,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scale: 0,
                transformOrigin: "left",
                opacity: 1,
                duration: .6,
                delay: .4
            }, 0)

            return tl
        },
        AnimateIn(tl, card) {
            let content_box = card.querySelector(".content_box")
            let content_box_line = content_box.querySelector(".content_box span")
            let content_box_text = content_box.querySelector(".content_box p span")
            let text_box = card.querySelector(".text_box")
            let bg_box = text_box.querySelector(".bg_box")
            let title = text_box.querySelector(".title")
            let sub_title = text_box.querySelector(".sub_title")

            //2 content line grow
            tl.fromTo(content_box_line, {
                scale: 0,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scale: 1,
                transformOrigin: "left",
                opacity: 1,
                duration: .6,
                delay: .4
            }, 2)
            //2 title up
            tl.fromTo(title, {
                y: "200%",
                opacity: 1,
            }, {
                y: "0%",
                opacity: 1,
                duration: .8,
                delay: .8,
                ease: "slow(0.7, 0.7, false)"
            }, 2)
            //2 sub_title up
            tl.fromTo(sub_title, {
                y: "200%",
                opacity: 1,
            }, {
                y: "0%",
                opacity: 1,
                duration: .8,
                delay: .9,
                ease: "slow(0.7, 0.7, false)"
            }, 2)
            //2 content text up
            tl.fromTo(content_box_text, {
                x: "-100%",
                opacity: 1,
            }, {
                x: "0%",
                opacity: 1,
                duration: .8,
                delay: 1.3,
                ease: "circ.inOut"
            }, 2)
            //2 bg grow
            tl.fromTo(bg_box, {
                scaleX: 0,
                transformOrigin: "left",
                opacity: 1,
            }, {
                scaleX: 1,
                transformOrigin: "left",
                opacity: 1,
                duration: 1,
                delay: 1.2,
                ease: "back.out(1.7)"
            }, 2)

            return tl
        }

    },
    watch: {
        page_image_loaded() {
            if (this.page_image_loaded == true && this.carousel_data.length > 0) {
                this.$nextTick(() => {
                    this.SetPageEnterGsap()
                })
            }
        },
        carousel_data() {
            if (this.page_image_loaded == true && this.carousel_data.length > 0) {
                this.$nextTick(() => {
                    this.SetPageEnterGsap()
                })
            }
        }
    },
    computed: {
        page_image_loaded() {
            return this.$store.state.page_image_loaded
        }
    },
    mounted() {
        clearTimeout(this.carousel_timer)
    },
    beforeDestory() {
        clearTimeout(this.carousel_timer)
        this.active_carousel = 0
    }
}