<template>
  <div id="HomeCarousel" class="relative w-full overflow-hidden home_carousel">
    <div
      class="overflow-hidden relative md:max-h-[56.25vw] md:h-[56.25vw] max-h-[100vh]"
    >
      <!--  -->
      <PageBullet
        @change-carousel="ChangeCarousel"
        :carousel_data="carousel_data"
        :active_carousel="active_carousel"
      />

      <ScrollCircle />

      <!-- active number -->
      <p
        class="active_number absolute z-10 lg:left-20 left-10 md:top-1/2 sm:top-1/3 sm:bottom-auto bottom-[19rem] transform md:-translate-y-1/2 md:text-base text-sm text-white tracking-widest font-josefin-sans md:block flex items-center"
      >
        {{ active_carousel | two_word_number }}
        <span
          class="pc_line w-[1px] h-20 md:block hidden bg-white mx-auto mt-5 transform scale-y-0"
        ></span>
        <span
          class="mb_line w-20 h-[1px] md:hidden block bg-white mx-auto ml-5 transform scale-x-0"
        ></span>
      </p>
      <!-- active number -->

      <!-- main carousel -->
      <div
        class="relative h-full min-w-full main_carousel_box whitespace-nowrap"
      >
        <div
          class="relative inline-block w-full h-full carousel_item gsap_item"
          v-for="item in carousel_data"
          :key="item.id"
        >
          <div
            class="text_box absolute z-[3] lg:left-40 sm:left-20 left-10 sm:top-[38%] sm:bottom-auto bottom-52 transform"
          >
            <h3
              class="flex items-center pr-10 overflow-hidden font-bold text-white"
            >
              <span class="relative z-10 block mr-6">
                <span
                  class="bg_box absolute pr-5 top-0 md:bottom-6 bottom-4 left-0 md:-right-4 -right-2 bg-main-orange-color transform z-[9]"
                ></span>
                <b
                  class="relative z-10 block pr-3 text-2xl italic font-bold title xl:text-6xl lg:text-5xl md:text-2xl sm:text-5xl font-montserrat"
                  >{{ item.title }}</b
                >
              </span>
              <strong
                class="relative z-10 block pr-5 text-6xl italic font-bold sub_title font-montserrat lg:text-8xl md:text-6xl sm:text-8xl whitespace-nowrap"
                >{{ item.strong_title }}</strong
              >
            </h3>

            <div
              class="flex items-center justify-start overflow-hidden content_box"
            >
              <span class="title_line flex-1 h-[1px] bg-white mr-5"></span>
              <p
                class="overflow-hidden sub_title tracking-wider text-white font-semibold xl:text-base sm:text-sm text-[8px]"
              >
                <span class="block">{{ item.sub_title }}</span>
              </p>
            </div>
          </div>
          <div
            class="dark_cover bg-black bg-opacity-60 absolute w-full h-full left-0 top-0 z-[2]"
          ></div>
          <div class="hidden h-full pb-0 md:block aspect-h-9 aspect-w-16">
            <img
              :src="item.pc_image"
              width="1920"
              height="1080"
              :alt="item.title + ' ' + item.strong_title + ' ' + item.sub_title"
              class="w-full h-full object-cover object-center relative z-[1]"
            />
          </div>
          <div class="block w-screen h-screen md:hidden">
            <img
              :src="item.mb_image"
              width="889"
              height="500"
              :alt="item.title + ' ' + item.strong_title + ' ' + item.sub_title"
              class="w-full h-full object-cover object-center z-[1]"
            />
          </div>
        </div>
      </div>
      <!-- main carousel -->
    </div>

    <!-- search bar -->
    <SearchBar />
    <!-- search bar -->
  </div>
</template>

<script>
import PageBullet from './Carousel/PageBullet.vue';
import ScrollCircle from './Carousel/ScrollCircle.vue';
import SearchBar from './Carousel/SearchBar.vue';
import CarouselGsap from '@/gsap/home/carousel';
export default {
  name: 'HomeCarousel',
  mixins: [CarouselGsap],
  components: {
    PageBullet,
    ScrollCircle,
    SearchBar,
  },
  props: {
    carousel_data: {
      require: true,
    },
  },
  filters: {
    two_word_number(val) {
      return val < 9 ? '0' + (val + 1) : val + 1;
    },
  },
};
</script>
