<template>
  <section id="FooterContact" class="relative pt-32 pb-10 md:pt-32 md:pb-32">
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10">
      <div class="">
        <div class="relative inline-block pt-2">
          <span
            class="absolute top-0 left-0 z-0 w-full sm:w-96 sm:h-10 h-7 bg-main-orange-color title_bg"
          ></span>
          <h2
            class="relative z-10 inline-block overflow-hidden text-5xl italic title sm:text-7xl"
          >
            <span class="block pr-3 font-bold font-montserrat">CONTACT US</span>
          </h2>
          <img
            class="absolute bottom-0 hidden transform translate-x-20 md:block left-full title_image"
            alt="background_line"
            width="200"
            height="41"
            src="/img/title_image_2.svg"
          />
        </div>
        <div class="flex items-center mt-4">
          <span
            class="w-32 h-[1px] bg-main-orange-color mr-5 sub_title_line"
          ></span>
          <h3
            class="overflow-hidden text-sm font-medium text-main-orange-color sub_title_text"
          >
            <span class="block">聯絡我們</span>
          </h3>
        </div>
      </div>
    </div>
    <div
      v-if="footer_data != null"
      class="relative flex flex-col items-center w-full mt-20 sm:flex-row md:pb-0 sm:pb-44"
    >
      <div
        class="relative z-10 flex items-center justify-center py-24 overflow-hidden contact_us_dealer card"
      >
        <div class="relative z-10 content">
          <div class="relative inline-block pt-2">
            <span
              class="absolute bottom-0 right-0 z-0 w-20 h-3 bg-main-orange-color"
            ></span>
            <h2
              class="relative z-10 inline-block pr-5 text-2xl italic font-bold text-white font-montserrat"
            >
              BECOME OUR DEALER
            </h2>
          </div>
          <div class="flex items-center mt-4">
            <span class="w-32 h-[1px] bg-white mr-5"></span>
            <h3 class="text-sm font-medium text-white">成為通路夥伴</h3>
          </div>

          <main_link_btn
            link="/become_dealer"
            class="mt-8"
            text_color="white"
            border_color="white"
            icon_color="white"
            text="CONTACT US"
          />
        </div>

        <div
          class="absolute top-0 bottom-0 left-0 right-0 z-10 bg_cover bg-main-orange-color"
        ></div>

        <img
          alt="聯絡瑪吉斯 maxxis s98"
          width="600"
          height="418"
          class="absolute top-0 left-0 z-0 object-cover object-center w-full h-full bg_image"
          :src="footer_data.footer_contact_us.image"
        />
      </div>
      <div
        class="bottom-0 right-0 z-20 flex items-center justify-center py-32 mt-10 transform sm:absolute md:top-1/2 md:bottom-auto md:-translate-y-1/2 sm:mt-0 contact_us card"
      >
        <div class="relative z-10 content">
          <div class="relative inline-block pt-2">
            <span
              class="absolute bottom-0 right-0 z-0 w-20 h-3 bg-main-orange-color"
            ></span>
            <h2
              class="relative z-10 inline-block pr-5 text-2xl italic font-bold text-white font-montserrat"
            >
              GET IN TOUCH
            </h2>
          </div>
          <div class="flex items-center mt-4">
            <span class="w-32 h-[1px] bg-white mr-5"></span>
            <h3 class="text-sm font-medium text-white">客戶服務</h3>
          </div>

          <main_link_btn
            link="/contact_us"
            class="mt-8"
            text_color="white"
            border_color="white"
            icon_color="white"
            text="CONTACT US"
          />
        </div>

        <div
          class="absolute top-0 bottom-0 left-0 right-0 z-20 bg-black bg_cover"
        ></div>

        <img
          alt="成為瑪吉斯 maxxis s98經銷商"
          width="600"
          height="418"
          class="absolute top-0 left-0 z-0 object-cover object-center w-full h-full bg_image"
          :src="footer_data.footer_become_dealer.image"
        />
      </div>
    </div>
    <div class="px-10 text-center mt-28 md:hidden sm:text-right">
      <ScrollTopBtn />
    </div>
  </section>
</template>

<script>
import main_link_btn from "@/components/main_link_btn";
import ScrollTopBtn from "./ScrollTopBtn.vue";
import FooterContactGsap from "@/gsap/footer_contact";
export default {
  name: "FooterContact",
  mixins: [FooterContactGsap],
  components: {
    main_link_btn,
    ScrollTopBtn,
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
  },
  created() {
    if (this.footer_data == null) {
      this.$store.dispatch("getFooterData");
    }
  },
};
</script>

<style>
#FooterContact .contact_us {
  width: 55%;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 0);
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 0);
}
#FooterContact .contact_us_dealer {
  width: 55%;
  padding-right: 5%;
}

@media (max-width: 768px) {
  #FooterContact .contact_us {
    width: 60%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 20% 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 20% 0);
  }
  #FooterContact .contact_us_dealer {
    width: 60%;
    -webkit-clip-path: polygon(100% 0, 80% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 0, 80% 100%, 0% 100%, 0 0);
  }
}

@media (max-width: 640px) {
  #FooterContact .contact_us {
    width: 100%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0);
  }
  #FooterContact .contact_us_dealer {
    width: 100%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0 0);
  }
}
</style>
