<template>
  <div
    class="absolute z-10 hidden transform scroll_circle right-10 md:top-1/2 md:bottom-auto bottom-36 md:-translate-y-1/2 sm:block"
  >
    <img
      class="absolute w-40 h-40 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
      alt="瑪吉斯 maxxis s98"
      width="160"
      height="160"
      src="/img/carousel/homepage_1.banner_img_scroll_lg_2.svg"
    />
    <img
      class="w-44 h-44"
      alt="瑪吉斯 maxxis s98 輪胎"
      width="176"
      height="176"
      src="/img/carousel/homepage_1.banner_img_lg_scroll.webp"
    />
  </div>
</template>

<script>
export default {
  name: "CarouselScrollCircle",
};
</script>
