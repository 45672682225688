<template>
  <div
    id="MainMenu"
    class="fixed top-0 z-40 w-full h-full max-h-screen overflow-y-auto -left-full bg-main-orange-color"
  >
    <button @click="Close" class="close_btn absolute top-10 right-10 z-[42]">
      <i class="text-sm text-white icon-close"></i>
    </button>
    <div
      class="main_content relative z-[41] lg:w-2/3 w-full lg:h-full sm:h-2/3 sm:min-h-0 min-h-full bg-main-orange-color flex items-center lg:justify-start justify-center xl:px-32 lg:px-20 md:px-32 px-20 sm:py-0 py-10"
    >
      <ol class="flex flex-wrap items-center w-full menu_list">
        <li
          v-for="item in menu_list"
          class="xl:w-[36%] sm:w-1/2 w-full mb-8 text-white"
          :key="item.id"
        >
          <router-link
            @click.native="Close"
            :to="item.link"
            class="text-center sm:text-left"
          >
            <h4 class="text-lg font-bold font-montserrat sm:text-2xl">
              {{ item.title }}
            </h4>
            <p class="text-sm font-medium sm:text-base">{{ item.sub_title }}</p>
          </router-link>
        </li>

        <li
          v-if="footer_data != null"
          class="flex items-center justify-center w-full sm:hidden"
        >
          <a class="mx-2" :href="footer_data.company_facebook.content">
            <i
              class="text-2xl text-white transition-all duration-300 icon-facebook hover:text-black"
            ></i>
          </a>
          <a class="mx-2" :href="footer_data.company_instagram.content">
            <i
              class="text-2xl text-white transition-all duration-300 icon-instagram hover:text-black"
            ></i>
          </a>
        </li>
      </ol>
    </div>
    <div
      class="bg_content absolute z-40 lg:w-1/2 w-full lg:h-full h-[48%] lg:top-0 lg:bottom-auto bottom-0 right-0 overflow-hidden sm:flex hidden"
    >
      <div
        class="w-full h-full absolute top-0 left-0 flex items-center justify-center lg:pl-[20%] lg:pt-0 pt-[10%]"
      >
        <div
          class="orange_cover bg-main-orange-color absolute top-0 left-0 w-full h-full z-[13]"
        ></div>
        <div class="content relative z-[12]">
          <img
            alt="瑪吉斯 maxxis s98 logo"
            width="192"
            height="21"
            src="/img/MAXXIS S98_W.png"
            class="block w-48"
          />

          <div
            class="flex items-center justify-center w-full mt-10"
            v-if="footer_data != null"
          >
            <a
              :href="footer_data.company_facebook.content"
              target="_blank"
              class="mx-2 text-white transition-all duration-300 hover:text-main-orange-color"
            >
              <i class="text-2xl icon-facebook"></i>
            </a>
            <a
              :href="footer_data.company_instagram.content"
              target="_blank"
              class="mx-2 text-white transition-all duration-300 hover:text-main-orange-color"
            >
              <i class="text-2xl icon-instagram"></i>
            </a>
          </div>
        </div>
        <div
          class="bg-black absolute top-0 left-0 w-full h-full opacity-80 z-[11]"
        ></div>
        <img
          alt="瑪吉斯 maxxis s98 電動車"
          class="absolute top-0 left-0 z-10 object-cover w-full h-full"
          src="/img/menu_bg.webp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MenuGsap from "@/gsap/main_menu";
export default {
  name: "MainMenu",
  mixins: [MenuGsap],
  data() {
    return {
      menu_active: false,
      menu_list: [
        {
          title: "HOMEPAGE",
          sub_title: "首頁",
          link: "/",
        },
        {
          title: "ABOUT US",
          sub_title: "關於我們",
          link: "/about",
        },
        {
          title: "BROWSE TIRES",
          sub_title: "輪胎列表",
          link: "/product_list/all",
        },
        {
          title: "COMPARE TIRES",
          sub_title: "比較輪胎",
          link: "/compare",
        },
        {
          title: "NEWS",
          sub_title: "最新消息",
          link: "/news_list/all",
        },
        {
          title: "VIDEO",
          sub_title: "影音專區",
          link: "/video_list",
        },
        {
          title: "FIND DEALER",
          sub_title: "尋找經銷商",
          link: "/dealers",
        },
        {
          title: "CONTACT US",
          sub_title: "聯絡我們",
          link: "/contact_us",
        },
      ],
    };
  },
  computed: {
    footer_data() {
      return this.$store.state.footer_data;
    },
  },
  created() {
    if (this.footer_data == null) {
      this.$store.dispatch("getFooterData");
    }
  },
};
</script>

<style>
#MainMenu .main_content {
  -webkit-clip-path: polygon(70% 0, 100% 100%, 0% 100%, 0% 0);
  clip-path: polygon(75% 0, 100% 100%, 0% 100%, 0% 0);
}

@media (max-width: 1024px) {
  #MainMenu .main_content {
    -webkit-clip-path: polygon(100% 0, 100% 80%, 0% 100%, 0% 0);
    clip-path: polygon(100% 0, 100% 80%, 0% 100%, 0% 0);
  }
}

@media (max-width: 640px) {
  #MainMenu .main_content {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 0);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 0% 0);
  }
}
</style>
