import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import prerender_event from "./common/prerender_event";
import "@/common/global_methods";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});
// image_loader
Vue.mixin(prerender_event);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
